.stepperWrapper1 {
  font-family: Arial;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 20px;
  height: 20vw;

  .stepperItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: -50%;
      z-index: 2;
    }

    &::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: 50%;
      z-index: 2;
    }

    &:first-child::before {
      content: none;
    }

    &:last-child::after {
      position: absolute;
      content: "";
      border-top: 2px solid #ccc;
      border-right: 2px solid #ccc;
      width: 100%;
      height: 50%;
      // top: 20px;
      top: 1vw;
      left: -20%;
      z-index: 2;
      border-top-right-radius: 1vw;
      border-bottom-right-radius: 1vw;
    }

    .stepCounter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 40px;
      width: 2vw;
      // height: 40px;
      height: 2vw;
      border-radius: 50%;
      background-color: #f2f4f7;
      color: white;
      margin-bottom: 0.5vw;

      .dotIcon {
        font-size: 0.65vw;
      }

      .checkIcon {
        font-size: 1vw;
      }

      i {
        position: relative;
        // font-size: 12px;
        &::before {
          line-height: 0;
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.85vw;
      color: #344054;
    }

    .active {
      color: #7f56d9 !important;
    }

    .completed {
      color: white !important;
      background-color: #7f56d9;
      box-shadow: 0px 0px 0px 4px #f2f4f7 !important;
    }
  }

  .completedAfter {
    &::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #7f56d9;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: 50%;
      z-index: 3;
    }

    &:last-child::after {
      position: absolute;
      content: "";
      border-top: 2px solid #7f56d9;
      border-right: 2px solid #7f56d9;
      width: 100%;
      height: 50%;
      // top: 20px;
      top: 1vw;
      left: -20%;
      z-index: 2;
      border-top-right-radius: 1vw;
      border-bottom-right-radius: 1vw;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  // .active {
  //   font-weight: bold;
  // }
}

.stepperWrapper3 {
  font-family: Arial;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 20vw;

  .stepperItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 80%;
      // top: 20px;
      top: 1vw;
      // left: 10%;
      z-index: 2;
    }

    &::after{
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 80%;
      // top: 20px;
      top: 1vw;
      // left: 10%;
      z-index: 2;
    }

    .completed {
      color: white !important;
      background-color: #7f56d9;
      box-shadow: 0px 0px 0px 4px #f2f4f7 !important;
    }
  }
}

.stepperWrapper2 {
  font-family: Arial;
  // margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 20vw;

  .stepperItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: -50%;
      z-index: 2;
    }

    &::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: 50%;
      z-index: 2;
    }

    &:first-child::before {
      margin-top: -41%;
      position: absolute;
      content: "";
      border-top: 2px solid #ccc;
      border-left: 2px solid #ccc;
      width: 300%;
      height: 51.5%;
      // top: 20px;
      // top: 1vw;
      left: 30%;
      z-index: 2;
      border-top-left-radius: 1vw;
      border-bottom-left-radius: 1vw;
    }

    &:last-child::after {
      content: none;
    }

    .stepCounter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 40px;
      width: 2vw;
      // height: 40px;
      height: 2vw;
      border-radius: 50%;
      background-color: #f2f4f7;
      color: white;
      margin-bottom: 0.5vw;

      .dotIcon {
        font-size: 0.65vw;
      }

      .checkIcon {
        font-size: 1vw;
      }

      i {
        position: relative;
        // font-size: 12px;
        &::before {
          line-height: 0;
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.85vw;
      color: #344054;
      margin: 0;
    }

    .active {
      color: #7f56d9 !important;
    }

    .completed {
      color: white !important;
      background-color: #7f56d9;
      box-shadow: 0px 0px 0px 4px #f2f4f7 !important;
    }
  }

  .completedAfter {
    &::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #7f56d9;
      width: 100%;
      // top: 20px;
      top: 1vw;
      left: 50%;
      z-index: 3;
    }

    &:first-child::before {
      margin-top: -40.3%;
      position: absolute;
      content: "";
      border-top: 2px solid #7f56d9;
      border-left: 2px solid #7f56d9;
      border-bottom: 2px solid #7f56d9;
      width: 300%;
      height: 50.7%;
      // top: 20px;
      // top: 1vw;
      left: 30%;
      z-index: 2;
      border-top-left-radius: 1vw;
      border-bottom-left-radius: 1vw;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  // .active {
  //   font-weight: bold;
  // }
}
