.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;

  .top {
    .tag {
      font-style: normal;
      font-weight: 500;
      // font-size: 14px;
      font-size: 0.75vw;
      // width: 65px;
      width: 3vw;

      text-align: center;

      color: #6941c6;
      border: 1.5px solid #7f56d9;
      border-radius: 16px;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;

      text-align: center;
      color: #101828;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      text-align: center;

      color: #475467;
    }
  }

  .bottom {
    .col1 {
      padding-right: 2vw;
    }

    .col2 {
    }

    .img {
      // width: 48px;
      width: 2.5vw;
      // height: 48px;
      height: 2.5vw;
      margin-bottom: 0.7vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 20px;
      font-size: 1.05vw;
      color: #101828;
      margin-bottom: 0.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      font-size: 0.835vw;
      // line-height: 24px;
      line-height: 1.25vw;
      color: #475467;
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 5% !important;
    padding-right: 5% !important;

    .top {
      .tag {
        font-weight: 500;
        font-size: 3vw;
        width: fit-content;
        padding: 0 2vw;
        border-radius: 3vw;
      }

      .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 4.5vw;
      }

      .text {
        font-weight: 400;
        font-size: 3.5vw;
      }
    }

    .bottom {
      .col1 {
        padding: 1vw 1vw !important;
      }

      .col2 {
        padding: 1vw 1vw !important;
      }

      .col {
        padding: 0 !important;
        text-align: start !important;
      }

      .img {
        display: none !important;

        width: 8vw;
        height: 8vw;
        margin-bottom: 2vw;
      }

      .header {
        font-weight: 500;
        font-size: 4vw;
        margin-bottom: 2vw;
      }

      .text {
        font-weight: 300;
        font-size: 3.2vw;
        line-height: 4vw;
      }
    }
  }
}
