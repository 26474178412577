.body {
  padding-left: 2% !important;
  padding-right: 2% !important;

  .top {
    margin-bottom: 2vw;
    padding: 0 1vw;

    .inputBox {
      width: 20vw;
      height: 3vw;

      input {
        font-size: 1vw;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .filterBox {
      min-width: 15vw;
      
    }
  }
}
