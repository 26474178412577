.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;

    text-align: center;
    color: #101828;
    margin-bottom: 3vw;
  }

  .card {
    padding-top: 2vw;
    .icon {
      // width: 60px;
      width: 3vw;
      // height: 60px;
      height: 3vw;
      margin-bottom: 1vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 30px;
      font-size: 1.6vw;
      color: #101828;
      margin-bottom: 1vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 18px;
      font-size: 0.938vw;
      // line-height: 28px;
      line-height: 1.45vw;
      color: #475467;
    }
  }
}
