.body {
  margin-top: 3vw;
  padding-left: 5%;
  padding-right: 5%;

  .heading {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;

    margin-bottom: 3vw;
  }

  .box {
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 3vw;

    .card {
      background-color: #f4f6fa;
      //   border-radius: 16px;
      border-radius: 0.8vw;
      padding: 2vw 3.9vw;

      .img {
        // width: 70px;
        width: 3.6vw;
        // height: 70px;
        height: 3.6vw;

        margin-top: 1vw;
      }

      .title {
        font-style: normal;
        font-weight: 400;
        // font-size: 28px;
        font-size: 1.5vw;

        margin-top: 1vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;

        margin-top: 1vw;
      }
    }
  }

  .btn1 {
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.85vw;
    color: #ffffff;

    padding: 0.7vw 2vw;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    // border-radius: 8px;
    border-radius: 0.425vw;
  }

  .btn2 {
    font-weight: 700;
    // font-size: 16px;
    font-size: 0.85vw;
    text-decoration-line: underline;
    color: #475467;

    margin-left: 1vw;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5%;
    padding-right: 2.5%;

    .heading {
      font-weight: 500;
      font-size: 5vw;

      margin-bottom: 5vw;
    }

    .box {
      padding-left: 1vw;
      padding-right: 1vw;
      margin-bottom: 3vw;

      .card {
        border-radius: 1vw;
        padding: 2vw 3.9vw;

        .img {
          width: 9vw;
          height: 9vw;

          margin-top: 1vw;
        }

        .title {
          font-weight: 500;
          font-size: 4vw;

          margin-top: 1vw;
        }

        .text {
          font-weight: 300;
          font-size: 2.7vw;

          margin-top: 1vw;
        }
      }
    }

    .btn1 {
      font-weight: 600;
      font-size: 3vw;
      color: #ffffff;

      padding: 1vw 4vw;
      border-radius: 1vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }

    .btn2 {
      width: 100%;

      font-weight: 700;
      font-size: 3vw;
      margin-left: 0;
    }
  }
}
