.intro {
  margin-top: 3vw;
  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 14px;
    font-size: 0.75vw;
    // line-height: 20px;
    color: #6941c6;
    width: fit-content;

    border: 1.5px solid #7f56d9;
    border-radius: 0.8vw;

    padding: 0.1vw 0.8vw;
    margin-bottom: 1vw;
  }

  .header {
    font-style: normal;
    font-weight: 600;
    // font-size: 60px;
    font-size: 3.15vw;
    // line-height: 72px;
    line-height: 3.75vw;
    color: #42307d;
    margin-bottom: 1vw;
  }

  .text {
    width: 70%;
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1.05vw;
    line-height: 1.59vw;
    color: #6941c6;
    margin-bottom: 3.5vw;
  }

  .btn {
    font-style: normal;
    font-weight: 600;
    // font-size: 18px;
    font-size: 0.95vw;
    color: #ffffff;

    background: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.4vw;

    padding: 0.6vw 2vw;
    margin-bottom: 1vw;
  }

  .box {
    .img1 {
      top: -8%;
      left: 0;
      z-index: -1;
    }

    .img2 {
      width: 70%;
    }
  }
}

.services {
  margin-top: 3vw;
  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 18px;
    font-size: 0.92vw;
    // line-height: 20px;
    color: #6941c6;

    border: 1.5px solid #7f56d9;
    border-radius: 3vw;
    padding: 0.3vw 0.7vw;
    margin-bottom: 1vw;
  }

  .header {
    font-style: normal;
    font-weight: 700;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 44px;
    color: #101828;
    margin-bottom: 1vw;
  }

  .text {
    font-style: normal;
    font-weight: 500;
    // font-size: 24px;
    font-size: 1.25vw;
    // line-height: 30px;

    color: #475467;
    margin-bottom: 2vw;
  }

  .container {
    background-color: #101828;
    padding: 3vw 7.5%;
    .card {
      .icon {
        // width: 80px;
        width: 4.15vw;
        // height: 80px;
        height: 4.15vw;
        margin-bottom: 1.5vw;
      }

      .title {
        font-style: normal;
        font-weight: 700;
        // font-size: 20px;
        font-size: 1.05vw;

        color: #ffffff;
        margin-bottom: 1vw;
      }

      .content {
        font-style: normal;
        font-weight: 400;
        // font-size: 18px;
        font-size: 0.938vw;
        line-height: 32px;
        line-height: 1.67vw;
        width: 90%;
        color: #ffffff;
      }
    }
  }
}

.platform {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;

  margin-top: 6vw;

  .body {
    background: #f6f4fb;
    border-radius: 1.1vw;
    padding-top: 3vw;
    padding-bottom: 6vw;

    .tag {
      font-style: normal;
      font-weight: 500;
      //   font-size: 14px;
      font-size: 0.75vw;

      color: #5925dc;

      border: 1.5px solid #6938ef;
      border-radius: 16px;

      padding: 0.1vw 0.6vw;
      margin-bottom: 1vw;
    }

    .header {
      font-style: normal;
      font-weight: 700;
      //   font-size: 36px;
      font-size: 1.9vw;
      color: #101828;
      margin-bottom: 6vw;
    }

    .container {
      width: 80%;

      img {
        height: 1.8vw;
      }
    }
  }
}

.noCode {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
  margin-top: 6vw;

  .header {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.85vw;
    color: #101828;
    margin-bottom: 3vw;
  }

  .card {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    margin-bottom: 3vw;
    .box {
      background: #f9fafb;
      border-radius: 24px;
      padding-top: 1vw;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-bottom: 1vw;

      .title {
        font-style: normal;
        font-weight: 600;
        // font-size: 20px;
        font-size: 1.05vw;
        color: #101828;
        margin-bottom: 0.5vw;
      }

      .content {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.835vw;
        // line-height: 24px;
        line-height: 1.25vw;
        color: #475467;
      }

      img {
        // width: 48px;
        width: 2.8vw;
        // height: 48px;
        height: 2.8vw;
        margin-bottom: 1.5vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .intro {
    margin-top: 3vw;
    .tag {
      font-weight: 500;
      font-size: 3vw;
      border-radius: 3vw;
      padding: 0 1.5vw;
      margin-bottom: 2vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      font-size: 4vw;
      line-height: 4vw;
      margin-bottom: 2vw;
    }

    .text {
      width: 90%;
      font-size: 3vw;
      line-height: 3vw;
      margin-bottom: 3.5vw;
    }

    .btn {
      font-style: normal;
      font-weight: 600;
      font-size: 3vw;
      border-radius: 1vw;
      padding: 0.5vw 3vw;
      margin-bottom: 2vw;
    }

    .box {
      .img1 {
        top: -8%;
        left: 0;
        z-index: -1;
      }

      .img2 {
        width: 90%;
      }
    }
  }

  .services {
    margin-top: 3vw;
    .tag {
      font-size: 3vw;
      border-radius: 3vw;
      padding: 0 1.5vw;
      margin-bottom: 2vw;
    }

    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 5vw;
      margin-bottom: 1vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 2.8vw;
      width: 95%;
      color: #475467;
      margin-bottom: 2vw;
    }

    .container {
      background-color: #101828;
      padding: 3vw 2.5%;
      .card {
        padding: 1vw !important;
        .icon {
          width: 7vw;
          height: 7vw;
          margin-bottom: 2vw;
        }

        .title {
          font-style: normal;
          font-weight: 700;
          // font-size: 20px;
          font-size: 3vw;

          color: #ffffff;
          margin-bottom: 2vw;
        }

        .content {
          font-style: normal;
          font-weight: 300;
          font-size: 2vw;
          line-height: 2.5vw;
          width: 90%;
        }
      }
    }
  }

  .platform {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    margin-top: 6vw;

    .body {
      background: #f6f4fb;
      border-radius: 2vw;
      padding-top: 4vw;
      padding-bottom: 4vw;

      .tag {
        font-style: normal;
        font-weight: 500;
        font-size: 3vw;

        border-radius: 3vw;

        padding: 0 1.5vw;
        margin-bottom: 2vw;
      }

      .header {
        font-style: normal;
        font-weight: 700;
        font-size: 3.5vw;
        margin-bottom: 6vw;
      }

      .container {
        width: 100%;
        img {
          height: 4vw;
          margin-bottom: 5vw;
        }
      }
    }
  }

  .noCode {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    margin-top: 8vw;

    .header {
      font-style: normal;
      font-weight: 600;
      font-size: 4vw;
      margin-bottom: 3vw;
    }

    .card {
      padding-left: 1vw;
      padding-right: 1vw;
      margin-bottom: 3vw;
      .box {
        background: #f9fafb;
        border-radius: 3vw;
        padding-top: 3vw;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-bottom: 2vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 3vw;
          margin-bottom: 2vw;
        }

        .content {
          font-style: normal;
          font-weight: 400;
          // font-size: 16px;
          font-size: 2.3vw;
          // line-height: 24px;
          line-height: 2.5vw;
          color: #475467;
        }

        img {
          width: 8vw;
          height: 8vw;
          margin-bottom: 1.5vw;
        }
      }
    }
  }
}
