.head {
  margin-top: 3vw;
  margin-bottom: 8vw;
  .top {
    padding-left: 3vw !important;
    padding-right: 3vw !important;

    .box {
      background: #1d2939;
      border-radius: 24px;
      padding-top: 4vw;
      padding-left: 2vw;
      padding-bottom: 3vw;

      .tag {
        font-style: normal;
        font-weight: 700;
        // font-size: 30px;
        font-size: 1.56vw;
        color: #ced1df;

        border: 1.5px solid #ced1df;
        border-radius: 3vw;
        padding: 1vw 3vw;
        margin-bottom: 3vw;
      }

      .header {
        font-style: normal;
        font-weight: 600;
        // font-size: 60px;
        font-size: 3.15vw;
        // line-height: 72px;
        line-height: 3.8vw;
        color: #ffffff;
        margin-bottom: 2vw;

        span {
          color: #53389e;
        }
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 26px;
        font-size: 1.35vw;
        // line-height: 38px;
        line-height: 1.9vw;
        color: #b7c7de;
        margin-bottom: 3vw;
      }

      .btn {
        font-style: normal;
        font-weight: 600;
        // font-size: 18px;
        font-size: 0.95vw;
        color: #ffffff;

        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;

        padding: 0.7vw 1.5vw;
      }
    }
    .bottomBg {
      bottom: 0;
      left: 0;
      background: #eef9fe;
      z-index: -1;
    }
  }

  .bottom {
    background: #eef9fe;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 9vw;

    .img {
      width: 90%;
      margin-bottom: 2vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 48px;
      line-height: 2.5vw;
      color: #000000;
      margin-bottom: 2vw;
    }

    .text1 {
      font-style: normal;
      font-weight: 600;
      // font-size: 29px;
      font-size: 1.51vw;
      // line-height: 42px;
      line-height: 2vw;

      color: #413e45;
      margin-bottom: 2vw;
    }

    .text2 {
      font-style: normal;
      font-weight: 700;
      // font-size: 27px;
      font-size: 1.5vw;
      // line-height: 48px;
      line-height: 2vw;
      color: #3d3d3d;
      margin-bottom: 2vw;
    }

    .btn {
      font-style: normal;
      font-weight: 600;
      // font-size: 18px;
      font-size: 1vw;
      color: #ffffff;

      background: #04a8f4;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0.5vw;

      padding: 0.5vw 2vw;
      margin-bottom: 3vw;
    }

    .line1 {
      left: 0;
      bottom: 0;
      width: 23%;
    }

    .line2 {
      right: 0;
      bottom: 0;
      width: 23%;
    }
  }
}

.work {
  padding-left: 5% !important;
  padding-right: 5% !important;
  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 24px;
    font-size: 1.2vw;
    color: #6941c6;

    border: 1.5px solid #7f56d9;
    border-radius: 2vw;

    padding: 0.5vw 2vw;
    margin-bottom: 2vw;
  }

  .header1 {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    color: #101828;
    margin-bottom: 1.5vw;
  }

  .text {
    font-style: normal;
    font-weight: 500;
    // font-size: 20px;
    font-size: 1.05vw;
    // line-height: 30px;

    color: #475467;

    margin-bottom: 3vw;
  }

  .container {
    background: #1a1a1a;
    box-shadow: 0px 4px 60px 30px rgba(127, 86, 217, 0.4);
    border-radius: 1.2vw;

    padding-top: 2vw;
    padding-bottom: 3vw;

    margin-bottom: 3vw;

    .icon {
      // width: 80px;
      width: 4.1vw;
      // height: 80px;
      height: 4.1vw;
      margin-bottom: 1vw;
    }

    .text {
      font-style: normal;
      font-weight: 600;
      // font-size: 24px;
      font-size: 1.25vw;

      color: #bdcbcf;
      margin-bottom: 1.5vw;
    }

    .result {
      font-style: normal;
      font-weight: 700;
      // font-size: 36px;
      font-size: 1.85vw;
      color: #ffffff;
    }
  }

  .header2 {
    font-style: normal;
    font-weight: 500;
    // font-size: 42px;
    font-size: 2.2vw;

    line-height: 3.2vw;

    color: #000000;
  }
}

.connect {
  padding-left: 5% !important;
  padding-right: 5% !important;

  .body {
    padding: 3% 4vw;
    background-color: #f6f2ff;
    border-radius: 16px;
    margin-top: 3vw;

    .leftCol {
      width: 80%;
      .header {
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        // line-height: 60px;
        line-height: 2.5vw;
        color: #000000;
        margin-bottom: 0.5vw;
      }
    }

    .btn {
      // width: 215px;
      // height: 42px;
      background-color: #7f56d9;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 0.6vw 2vw;

      color: white;
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.9vw;
    }
  }
}

@media only screen and (max-width: 600px) {
  .head {
    margin-top: 3vw;
    margin-bottom: 8vw;
    .top {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .box {
        background: #1d2939;
        border-radius: 24px;
        padding-top: 6vw;
        padding-left: 2vw;
        padding-bottom: 3vw;

        img {
          display: none;
        }

        .leftCol {
          align-items: center !important;

          .tag {
            font-style: normal;
            font-weight: 600;
            font-size: 3vw;
            color: #ced1df;

            border: 1.5px solid #ced1df;
            border-radius: 3vw;
            padding: 0.5vw 3vw;
            margin-bottom: 3vw;
          }

          .header {
            text-align: center;
            font-weight: 600;
            font-size: 4vw;
            line-height: 3.8vw;
            color: #ffffff;
            margin-bottom: 4vw;
          }

          .text {
            text-align: center;

            font-weight: 400;
            font-size: 3vw;
            line-height: 3.5vw;
            margin-bottom: 4vw;
          }

          .btn {
            font-style: normal;
            font-weight: 600;
            font-size: 3vw;

            border-radius: 1vw;

            padding: 1vw 3vw;

            margin-bottom: 3vw;
          }
        }
      }
      .bottomBg {
        bottom: 0;
        left: 0;
        background: #eef9fe;
        z-index: -1;
      }
    }

    .bottom {
      background: #eef9fe;
      padding-left: 2.5% !important;
      padding-right: 2.5% !important;
      padding-top: 9vw;

      .img {
        width: 95%;
        margin-bottom: 4vw;
      }

      .header {
        font-weight: 600;
        font-size: 3vw;
        line-height: 3.5vw;
        margin-bottom: 2vw;
        width: 95%;
      }

      .text1 {
        font-weight: 400;
        font-size: 2.7vw;
        line-height: 3vw;
        margin-bottom: 2vw;
        width: 95%;
      }

      .text2 {
        font-style: normal;
        font-weight: 400;
        font-size: 2.7vw;
        line-height: 3vw;
        margin-bottom: 4vw;
      }

      .btn {
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        border-radius: 1vw;
        padding: 1vw 3vw;
        margin-bottom: 5vw;
      }

      .line1 {
        left: 0;
        bottom: 0;
        width: 23%;
      }

      .line2 {
        right: 0;
        bottom: 0;
        width: 23%;
      }
    }
  }

  .work {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    .tag {
      font-weight: 500;
      font-size: 3vw;
      border-radius: 3vw;
      padding: 0.5vw 2vw;
      margin-bottom: 3vw;
    }

    .header1 {
      font-style: normal;
      font-weight: 600;
      font-size: 4vw;
      margin-bottom: 2vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 3vw;
      width: 90%;
      margin-bottom: 3vw;
    }

    .container {
      border-radius: 3vw;
      padding-top: 6vw;
      padding-bottom: 2vw;
      margin-bottom: 1vw;

      .icon {
        width: 8vw;
        height: 8vw;
        margin-bottom: 1vw;
      }

      .text {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        // font-size: 24px;
        font-size: 2.5vw;
        color: #bdcbcf;
        margin-bottom: 1.5vw;
      }

      .result {
        font-style: normal;
        font-weight: 700;
        // font-size: 36px;
        font-size: 3vw;
        color: #ffffff;
        margin-bottom: 7vw;
      }
    }

    .header2 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.5vw;

      line-height: 4vw;

      color: #000000;
    }
  }

  .connect {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    .body {
      padding: 5vw 3vw;
      background-color: #f6f2ff;
      border-radius: 16px;
      margin-top: 3vw;

      .innerBody {
        flex-direction: column !important;
        .leftCol {
          width: 100%;
          text-align: center;
          padding: 0 !important;
          .header {
            font-style: normal;
            font-weight: 600;
            font-size: 3.5vw;
            line-height: 4vw;
            margin-bottom: 3vw;
          }
        }

        .rightCol {
          padding: 0 !important;

          .btn {
            border-radius: 1vw;
            padding: 0.5vw 3vw;
            font-weight: 600;
            font-size: 4vw;
          }
        }
      }
    }
  }
}
