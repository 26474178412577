.body {
  margin-top: 5vw;
  .top {
    padding-left: 5% !important;
    padding-right: 5% !important;

    .heading {
      font-style: normal;
      font-weight: 500;
      // font-size: 36px;
      font-size: 1.8vw;
      // line-height: 48px;
      line-height: 2.5vw;
      margin-bottom: 0;
    }

    .processTag {
      // width: 85px;
      width: fit-content;
      padding: 0 0.5vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 0.8vw;
      font-style: normal;
      font-weight: 400;
      // font-size: 14px;
      font-size: 0.75vw;
      // line-height: 20px;
      line-height: 1.2vw;
    }

    .defaultColor {
      color: #6941c6 !important;
    }

    .stepperWrapper {
      font-family: Arial;
      display: flex;
      justify-content: space-between;

      margin-top: 2vw;

      .stepperItem {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        cursor: pointer;

        &::before {
          position: absolute;
          content: "";
          border-bottom: 2px solid #ccc;
          width: 100%;
          top: 1.25vw;
          left: -50%;
          z-index: 2;
        }

        &::after {
          position: absolute;
          content: "";
          border-bottom: 2px solid #ccc;
          width: 100%;
          top: 1.25vw;
          left: 50%;
          z-index: 2;
        }

        &:first-child::before {
          content: none;
        }

        &:last-child::after {
          content: none;
        }

        .stepCounter {
          position: relative;
          z-index: 5;
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 32px;
          width: 2.5vw;
          // height: 32px;
          height: 2.5vw;
          font-size: 1vw;
          font-weight: 600;
          border-radius: 50%;
          background: #f9f5ff;
          color: #98a2b3;
          margin-bottom: 0.2vw;

          border: 1px solid #7f56d9 !important;
          box-shadow: 0px 0px 0px 4px #f4ebff;
        }

        .title {
          font-style: normal;
          font-weight: 600;
          // font-size: 16px;
          font-size: 0.9vw;
          // line-height: 20px;
          line-height: 1.4vw;
          color: #344054;

          margin-top: 1vw;
        }

        .active {
          color: #7f56d9 !important;
        }

        .completed {
          color: white !important;
          background-color: #7f56d9;
          box-shadow: none;
        }
      }

      .completedAfter {
        &::after {
          position: absolute;
          content: "";
          border-bottom: 2px solid #7f56d9;
          width: 100%;
          top: 1.25vw;

          left: 50%;
          z-index: 3;
        }
      }

      // .active {
      //   font-weight: bold;
      // }
    }

    // .progressBar {
    //   .circle {
    //     color: #98a2b3;
    //     background-color: #f9f5ff;
    //     border: 1px solid #7f56d9 !important;
    //     box-shadow: 0px 0px 0px 4px #f4ebff;
    //     width: 32px;
    //     height: 32px;
    //     cursor: pointer;
    //   }

    //   .circleActive {
    //     color: white !important;
    //   }

    //   .active {
    //     background-color: #7f56d9;
    //     box-shadow: none;
    //   }

    //   .text {
    //     font-weight: 600;
    //     font-size: 16px;
    //   }

    //   .textActive {
    //     color: #7f56d9 !important;
    //   }

    //   .line {
    //     z-index: -1 !important;
    //     top: 50%;
    //     width: 98%;
    //     border: 1px solid #f9f5ff !important;
    //   }
    // }
  }

  .bottomBody {
    padding-left: 5% !important;
    margin-top: 3vw;

    .leftCol {
      padding-top: 3vw;
      .scroller {
        width: 85%;
        // max-height: 550px;
        max-height: 29vw;
        // min-height: 550px;
        min-height: 29vw;
        overflow-y: auto;

        .featuredBg {
          background-color: #f9fafb;
          padding: 1.3vw 1vw;
          margin-bottom: 2vw;

          .imgBox {
            width: 2.5vw;
            // height: 48px;
            height: 2.5vw;
            background-color: #d0d5dd;
            border-radius: 0.6vw;
          }

          .active {
            background-color: #7f56d9 !important;
          }

          img {
            width: 70%;
            height: 70%;
            // width: 48px;
            // width: 2.5vw;
            // height: 48px;
            // height: 2.5vw;
          }

          .text {
            font-style: normal;
            font-weight: 500;
            // font-size: 20px;
            font-size: 1.1vw;
            margin-left: 1vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 5vw;
    .top {
      padding-left: 5% !important;
      padding-right: 5% !important;

      .heading {
        font-style: normal;
        font-weight: 500;
        font-size: 3.5vw;
        line-height: 4vw;
        margin-bottom: 2vw;
      }

      .processTag {
        width: fit-content;
        padding: 1vw 1.5vw;
        border-radius: 3vw;
        font-size: 3vw;
        line-height: 3vw;
      }

      .stepperWrapper {
        font-family: Arial;
        display: flex;
        justify-content: space-between;

        margin-top: 2vw;

        .stepperItem {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          cursor: pointer;

          &::before {
            position: absolute;
            content: "";
            border-bottom: 2px solid #ccc;
            width: 100%;
            top: 2.5vw;
            left: -50%;
            z-index: 2;
          }

          &::after {
            position: absolute;
            content: "";
            border-bottom: 2px solid #ccc;
            width: 100%;
            top: 2.5vw;
            left: 50%;
            z-index: 2;
          }

          &:first-child::before {
            content: none;
          }

          &:last-child::after {
            content: none;
          }

          .stepCounter {
            width: 5vw;
            height: 5vw;
            font-size: 3vw;
            font-weight: 400;
            border-radius: 50%;
            margin-bottom: 0.2vw;
          }

          .title {
            font-weight: 500;
            font-size: 1.8vw;
            line-height: 3vw;

            margin-top: 2vw;
          }

          .active {
            color: #7f56d9 !important;
          }

          .completed {
            color: white !important;
            background-color: #7f56d9;
            box-shadow: none;
          }
        }

        .completedAfter {
          &::after {
            position: absolute;
            content: "";
            border-bottom: 2px solid #7f56d9;
            width: 100%;
            top: 2.5vw;

            left: 50%;
            z-index: 3;
          }
        }
      }
    }

    .bottomBody {
      padding-left: 5% !important;
      padding-right: 5% !important;
      margin-top: 3vw;

      .leftCol {
        padding-top: 0vw;
        .scroller {
          width: 100%;
          max-height: 42vw;
          min-height: 42vw;
          overflow-y: auto;

          .featuredBg {
            background-color: #f9fafb;
            padding: 1.3vw 1vw;
            margin-bottom: 2vw;

            .imgBox {
              width: 2.5vw;
              height: 2.5vw;
              background-color: #d0d5dd;
              border-radius: 0.6vw;
            }

            .active {
              background-color: #7f56d9 !important;
            }

            img {
              width: 50%;
              height: 50%;
            }

            .text {
              font-style: normal;
              font-weight: 400;
              font-size: 2.7vw;
              margin-left: 1vw;
            }
          }
        }
      }

      .rightCol {
        display: none;
      }
    }
  }
}
