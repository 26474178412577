.body {
  padding-left: 5% !important;
  margin-top: 3vw;

  .ideasTag {
    // width: 180px;
    color: #6941c6;
    border-color: #6941c6 !important;
    border-radius: 14px;
    // font-size: 13px;

    line-height: 1.3vw;
    font-size: 0.75vw;
    width: fit-content;
    padding: 0 0.5vw;
  }

  .heading {
    font-weight: 500;
    font-size: 2.9vw;
    margin-bottom: 1.5vw;
    width: 85%;
  }

  .list {
    color: #475467;
    font-weight: 300;
    font-style: normal;
    font-size: 1.1vw;
    line-height: 2.5vw;
  }

  ul {
    padding-left: 2vw;
  }

  .contactBtn {
    color: #344054;
    background-color: white;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.4vw;

    width: 9vw;
    margin-top: 1.5vw;
    // padding-top: 0.5vw;
    // padding-bottom: 0.5vw;

    margin-right: 0.8vw;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8vw;
    // font-size: 15px;

    line-height: 1.8vw;
  }

  .aboutBtn {
    color: white;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.4vw;

    width: 9vw;
    margin-top: 1.5vw;
    // padding-top: 0.5vw;
    // padding-bottom: 0.5vw;

    font-style: normal;
    font-weight: 500;
    font-size: 0.8vw;
    // font-size: 15px;
    line-height: 1.8vw;

    &:hover {
      color: white;
    }
  }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (max-width: 600px) {
  .body {
    .ideasTag {
      border-radius: 3vw;
      font-size: 3vw;
      width: fit-content;
      padding: 1.5vw 1vw;
    }

    .heading {
      font-weight: 500;
      font-size: 5vw;
      margin-bottom: 3vw;
      width: 85%;
    }

    .list {
      color: #475467;
      font-weight: 300;
      font-style: normal;
      font-size: 2.5vw;
      line-height: 2.8vw;
    }

    ul {
      padding-left: 6vw;
      li {
        margin-bottom: 1vw;
      }
    }

    .contactBtn {
      border-radius: 1vw;

      width: fit-content;

      margin-right: 0.8vw;
      font-style: normal;
      font-weight: 400;
      font-size: 2.5vw;
    }

    .aboutBtn {
      border-radius: 1vw;
      width: fit-content;

      font-style: normal;
      font-weight: 500;
      font-size: 2.5vw;
      line-height: 1.8vw;
    }

    .imageBox {
      display: none;
    }
  }
}
