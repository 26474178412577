.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
  padding-top: 6vw;
  margin-bottom: 4vw;

  .img {
    // width: 56px;
    width: 2.9vw;
    // height: 56px;
    height: 2.9vw;
    margin-bottom: 1vw;
  }

  .header {
    font-style: normal;
    font-weight: 700;
    // font-size: 42px;
    font-size: 2.2vw;
    color: #101828;
  }

  .box {
    padding-left: 0;
    margin-bottom: 1vw;
    .imgBox {
      //   width: 35px;
      width: 1.85vw;
      //   height: 35px;
      height: 1.85vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      //   font-size: 20px;
      font-size: 1.05vw;
      color: #101828;
      padding-left: 0.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 16px;
      font-size: 0.83vw;
      // line-height: 24px;
      line-height: 1.25vw;
      padding-left: 0.5vw;
      color: #475467;
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    padding-top: 6vw;
    margin-bottom: 4vw;

    .img {
      width: 7vw;
      height: 7vw;
      margin-bottom: 1vw;
      display: none;
    }

    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 5vw;
      width: 100%;
      text-align: center;
      margin-bottom: 3vw;
    }

    .box {
      padding: 2vw;
      margin-top: 0;
      margin-bottom: 0;
      .imgBox {
        width: 5vw;
        height: 5vw;
      }

      .heading {
        font-weight: 600;
        font-size: 4vw;
        padding-left: 1vw;
      }

      .text {
        font-size: 3vw;
        line-height: 4vw;
        padding-left: 1vw;
      }
    }
  }
}
