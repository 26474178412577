.body {
  .top {
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: #fbfbfd;
    padding-left: 5% !important;
    padding-right: 5% !important;

    .processTag {
      // width: 100px;
      width: 5.2vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 15px;
      font-size: 14px;
      font-size: 0.74vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 1.9vw;
      margin-bottom: 3vw;
    }

    .cardBox {
      .card {
        img {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }

        .text {
          font-weight: 600;
          // font-size: 20px;
          font-size: 1.05vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    .top {
      padding-top: 7vw;
      padding-bottom: 3vw;
      padding-left: 5% !important;
      padding-right: 5% !important;

      .processTag {
        width: fit-content;
        border-radius: 3vw;
        font-size: 3vw;
        padding: 0 1.5vw;
      }

      .heading {
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        margin-bottom: 5vw;
      }

      .cardBox {
        justify-content: center !important;
        .card {
          margin-bottom: 2vw;

          img {
            width: 7vw;
            height: 7vw;
          }

          .text {
            font-weight: 400;
            font-size: 2.2vw;
          }
        }
      }
    }
  }
}
