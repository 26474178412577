.body {
  margin-top: 4vw;
  .top {
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 4vw;
    margin-bottom: 5vw;
    .leftCol {
      width: 35%;

      .header1 {
        // font-size: 32px;
        font-size: 1.7vw;
        font-weight: 400;
        color: #7f56d9;
      }

      .header2 {
        // font-size: 42px;
        font-size: 2.19vw;
        font-weight: 700;
        color: #101828;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;
        color: #101828;
        width: 85%;
      }
    }
    .rightCol {
      width: 65%;

      .card {
        margin-bottom: 2.5vw;
        img {
          // width: 40px;
          width: 2vw;
          // height: 40px;
          height: 2vw;
        }

        .content {
          margin-top: 0.3vw;
          margin-left: 0.5vw;
          font-style: normal;
          font-weight: 400;
          // font-size: 20px;
          font-size: 1.05vw;

          color: #283349;
        }
      }
    }
  }

  .mid {
    width: 100%;
    .container {
      width: 90%;
      background-color: #f6f3fc;
      border-radius: 24px;

      padding: 3vw 4vw;

      .header {
        margin: 0;
        width: 74%;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.88vw;
        color: #101828;
        text-transform: uppercase;
      }

      .btn {
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.85vw;
        color: #ffffff;

        padding: 0.6vw 1.5vw;
        text-transform: uppercase;

        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
      }
    }
  }

  .bottom {
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 5vw;
    padding-bottom: 6vw;

    h1 {
      font-style: normal;
      font-weight: 600;
      // font-size: 42px;
      font-size: 2.2vw;
      text-align: center;
    }

    .text {
      margin-top: 2vw;

      font-style: normal;
      font-weight: 400;
      // font-size: 22px;
      font-size: 1.147vw;
    }

    .card1 {
      margin-top: 2vw;
      padding-right: 7.2vw;

      img {
        // width: 40px;
        width: 2vw;
        // height: 40px;
        height: 2vw;
      }

      .content {
        margin-top: 0.3vw;
        margin-left: 0.5vw;
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.045vw;
      }

      .btn1 {
        margin-top: 0.5vw;
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.85vw;
        text-transform: uppercase;
        color: #ffffff;

        height: 65%;

        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
      }

      .btn2 {
        margin-top: 0.5vw;
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.85vw;
        text-transform: uppercase;
        color: #344054;

        height: 65%;

        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
      }
    }

    .card2 {
      margin-top: 2vw;
      padding-right: 4vw;

      img {
        // width: 40px;
        width: 2vw;
        // height: 40px;
        height: 2vw;
      }

      .content {
        margin-top: 0.3vw;
        margin-left: 0.5vw;
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.045vw;
      }

      .btn1 {
        margin-right: 1vw;
        margin-left: 4vw;
        margin-top: 0.5vw;
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.85vw;
        text-transform: uppercase;
        color: #ffffff;

        // height: 65%;

        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.3vw;
      }

      .btn2 {
        margin-right: 4vw;
        margin-left: 1vw;
        margin-top: 0.5vw;
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.85vw;
        text-transform: uppercase;
        color: #344054;

        // height: 65%;

        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.3vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 4vw;
    .top {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 4vw;
      margin-bottom: 5vw;
      flex-direction: column !important;
      .leftCol {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .header1 {
          font-size: 3.5vw;
          font-weight: 500;
          margin-bottom: 1vw;
        }

        .header2 {
          font-size: 4vw;
          font-weight: 700;
        }

        .text {
          font-weight: 500;
          // font-size: 24px;
          font-size: 3vw;
          width: 100%;
        }
      }
      .rightCol {
        width: 100%;

        .card {
          margin-bottom: 2.5vw;
          img {
            margin-top: 0.2vw;
            width: 3.5vw;
            height: 3.5vw;
          }

          .content {
            margin-top: 0.3vw;
            margin-left: 0.5vw;
            font-style: normal;
            font-weight: 400;
            font-size: 2.5vw;
          }
        }
        .content {
          text-align: center;
          margin-top: 0.3vw;
          margin-left: 0.5vw;
          font-style: normal;
          font-weight: 400;
          font-size: 2.75vw;
        }
      }
    }

    .mid {
      width: 100%;
      .container {
        width: 90%;
        border-radius: 2vw;

        padding: 5vw 4vw;
        flex-direction: column !important;
        align-items: center;
        text-align: center;

        .header {
          margin: 0;
          width: 90%;
          font-weight: 500;
          font-size: 3vw;
          margin-bottom: 3vw;
        }

        .btn {
          font-style: normal;
          font-weight: 500;
          font-size: 2.8vw;

          padding: 0.5vw 3vw;
          text-transform: uppercase;
          border-radius: 1vw;
        }
      }
    }

    .bottom {
      padding-left: 5% !important;
      padding-right: 5% !important;
      padding-top: 5vw;
      padding-bottom: 6vw;

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 3.5vw;
      }

      .text {
        margin-top: 2vw;

        font-style: normal;
        font-weight: 400;
        font-size: 2.8vw;
      }

      .card1 {
        margin-top: 2vw;
        padding-right: 1vw;
        padding-left: 0;

        img {
          width: 3.5vw;
          height: 3.5vw;
        }

        .content {
          margin-top: 0;
          margin-left: 1vw;
          font-style: normal;
          font-weight: 400;
          // font-size: 20px;
          font-size: 2.5vw;
        }

        .btn1 {
          font-size: 3vw;
          width: 50% !important;
          height: auto;

          border-radius: 1vw;
        }

        .btn2 {
          margin-top: 0.5vw;
          font-size: 3vw;
          width: 50% !important;
          height: auto;

          border-radius: 1vw;
        }
      }

      .card2 {
        margin-top: 2vw;
        padding-right: 0;
        padding-left: 3vw;

        img {
          width: 3.5vw;
          height: 3.5vw;
        }

        .content {
          margin-top: 0;
          margin-left: 1vw;
          font-style: normal;
          font-weight: 400;
          // font-size: 20px;
          font-size: 2.5vw;
        }

        .btn1 {
          font-size: 3vw;
          width: 100% !important;
          height: auto !important;
          margin-left: 0;
          margin-right: 0;

          border-radius: 1vw;
        }

        .btn2 {
          margin-top: 0.5vw;
          font-size: 3vw;
          width: 100% !important;
          height: auto !important;
          margin-right: 0;
          margin-left: 0;

          border-radius: 1vw;
        }
      }

      .centerButton {
        justify-content: center;
      }
    }
  }
}
