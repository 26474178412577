.body1 {
  width: 90%;
  padding: 3% 4vw;
  background-color: #53389e;
  border-radius: 16px;

  .leftCol {
    width: 65%;

    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 60px;
      line-height: 2.5vw;
      color: white;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      color: #e9d7fe;
      margin-bottom: 2vw;
    }
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #7f56d9;

    border: 1px solid #7f56d9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    // padding: 10px 30px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body2 {
  width: 90%;
  padding: 3% 4vw;
  background-color: #1d2939;
  border-radius: 16px;

  .leftCol {
    width: 65%;
    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 60px;
      line-height: 2.5vw;
      color: white;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      color: #e9d7fe;
      margin-bottom: 2vw;
    }
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #7f56d9;

    border: 1px solid #7f56d9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body3 {
  width: 90%;
  padding-top: 3vw;
  padding-bottom: 3vw;
  background-color: #53389e;
  border-radius: 16px;

  .header {
    width: 60%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: white;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1.05vw;
    color: #e9d7fe;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #7f56d9;

    border: 1px solid #7f56d9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body4 {
  width: 90%;
  // height: 240px;
  padding-top: 3vw;
  padding-bottom: 3vw;
  background-color: #1d2939;
  border-radius: 16px;

  .header {
    width: 60%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: white;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1.05vw;
    color: #e9d7fe;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #7f56d9;

    border: 1px solid #7f56d9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body5 {
  width: 90%;
  padding: 3% 4vw;
  background-color: #53389e;
  border-radius: 16px;
  padding-left: 5%;
  padding-right: 5%;
  .leftCol {
    width: 65%;
    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 60px;
      line-height: 2.5vw;
      color: white;
      margin-bottom: 0.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 26px;
      font-size: 1.35vw;
      color: #e9d7fe;
      margin-bottom: 2vw;
    }
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #7f56d9;

    border: 1px solid #7f56d9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body6 {
  width: 90%;
  // height: 240px;
  padding-top: 3vw;
  padding-bottom: 3vw;
  background-color: #636f73;
  border-radius: 16px;

  .header {
    width: 80%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: white;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1.05vw;
    color: #e9d7fe;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background-color: #262c2d;

    border: 1px solid #262c2d;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body7 {
  width: 90%;
  // height: 240px;
  padding-top: 3vw;
  padding-bottom: 3vw;
  background-color: #333333;
  border-radius: 16px;

  .header {
    width: 80%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: white;
  }

  .text {
    width: 65%;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    font-size: 1.55vw;
    color: #e7e7e7;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background: #7f56d9;
    /* Primary/600 */

    border: 1px solid #7f56d9;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body8 {
  width: 90%;
  // height: 240px;
  padding-top: 3vw;
  padding-bottom: 3vw;
  background-color: #f7f6fa;
  border-radius: 16px;

  .header {
    width: 80%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: #101828;
  }

  .text {
    width: 65%;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    font-size: 1.55vw;
    color: #5a6274;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background: #7f56d9;
    /* Primary/600 */

    border: 1px solid #7f56d9;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

.body9 {
  width: 90%;
  // height: 240px;
  padding-top: 3vw;
  padding-bottom: 4vw;
  background-color: #1d2939;
  border-radius: 16px;

  .header {
    width: 80%;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    // line-height: 60px;
    line-height: 2.5vw;
    color: #ffffff;
  }

  .text {
    width: 64%;
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1.05vw;
    // line-height: 48px;
    line-height: 2.4vw;
    color: #e2d9ed;
    margin-bottom: 2vw;
  }

  .btn {
    // width: 215px;
    // height: 42px;
    background: #7f56d9;
    /* Primary/600 */

    border: 1px solid #7f56d9;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0.6vw 1.7vw;

    color: white;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    font-size: 0.9vw;
  }
}

@media only screen and (max-width: 600px) {
  .body1 {
    width: 90%;
    padding: 5vw 4vw;
    border-radius: 2vw;
    flex-direction: column !important;
    .leftCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .header {
        font-weight: 500;
        font-size: 3vw;
        line-height: 3.5vw;
        margin-bottom: 2vw;
        width: 90%;
      }

      .text {
        font-style: normal;
        font-weight: 300;
        font-size: 2.5vw;
        width: 90%;
        margin-bottom: 2vw;
      }
    }

    .btn {
      border-radius: 1vw;
      padding: 0.6vw 2vw;

      font-weight: 500;
      font-size: 2.5vw;
    }
  }

  .body2 {
    width: 90%;
    padding: 5vw 4vw;
    border-radius: 2vw;
    flex-direction: column !important;
    .leftCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .header {
        font-weight: 500;
        font-size: 3vw;
        line-height: 3.5vw;
        margin-bottom: 2vw;
        width: 90%;
      }

      .text {
        font-style: normal;
        font-weight: 300;
        font-size: 2.5vw;
        width: 90%;
        margin-bottom: 2vw;
      }
    }

    .btn {
      border-radius: 1vw;
      padding: 0.6vw 2vw;

      font-weight: 500;
      font-size: 2.5vw;
    }
  }

  .body3 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }

  .body4 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }

  .body5 {
    width: 90%;
    padding: 5vw 4vw;
    border-radius: 2vw;
    flex-direction: column !important;
    .leftCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .header {
        font-weight: 500;
        font-size: 3vw;
        line-height: 3.5vw;
        margin-bottom: 2vw;
        width: 90%;
      }

      .text {
        font-style: normal;
        font-weight: 300;
        font-size: 2.5vw;
        width: 90%;
        margin-bottom: 2vw;
      }
    }

    .btn {
      border-radius: 1vw;
      padding: 0.6vw 2vw;

      font-weight: 500;
      font-size: 2.5vw;
    }
  }

  .body6 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }

  .body7 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }

  .body8 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }

  .body9 {
    width: 90%;
    // height: 240px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 3vw;

    .header {
      width: 80%;
      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }

    .text {
      font-style: normal;
      font-weight: 300;
      width: 80%;
      font-size: 2.5vw;
      margin-bottom: 2vw;
    }

    .btn {
      border-radius: 1vw;
      padding: 0.5vw 2vw;

      font-weight: 500;
      font-size: 3vw;
    }
  }
}
