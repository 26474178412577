.about {
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin-top: 2vw;

  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 20px;
    font-size: 1.05vw;
    color: #6941c6;

    border: 1.5px dashed #7f56d9;
    border-radius: 1.2vw;
    padding: 0.4vw 1.5vw;
    margin-bottom: 2vw;
  }

  .top {
    margin-bottom: 1vw;
    .header {
      font-style: normal;
      font-weight: 600;
      // font-size: 46px;
      font-size: 2.39vw;
      // line-height: 76px;
      line-height: 3.95vw;
      color: #000000;
      width: 90%;
      span {
        color: #6941c6;
      }
    }

    .content {
      font-style: normal;
      font-weight: 500;
      // font-size: 27px;
      font-size: 1.41vw;
      // line-height: 48px;
      line-height: 2.5vw;
      color: #000000;
    }
  }

  .bottom {
    padding-top: 15vw;

    .imgContainer {
      top: 0;
      z-index: 1;
    }

    .leftCol {
      padding-top: 26vw;

      .line {
        top: 7%;
        left: -13%;
        height: 63%;
        // height: 636px;
      }

      .box1 {
        margin-bottom: 4vw;
        padding-right: 1.5vw;

        img {
          filter: drop-shadow(0px 15px 40px rgba(0, 0, 0, 0.07));
          border-radius: 24px;
        }
      }
    }

    .rightCol {
      padding-top: 28vw;
      padding-bottom: 2vw;
      padding-left: 3vw;
      padding-right: 3vw;
      background-color: #faf9fd;
      border-radius: 24px;

      .tag {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        font-size: 1.05vw;
        color: #6941c6;

        border: 1.5px dashed #7f56d9;
        border-radius: 34px;
        width: fit-content;
        padding: 0.5vw 3vw;
        margin-bottom: 2vw;
      }

      .header {
        font-style: normal;
        font-weight: 700;
        // font-size: 36px;
        font-size: 1.85vw;
        color: #101828;
        margin-bottom: 2vw;
      }

      .text {
        font-style: normal;
        font-weight: 500;
        // font-size: 27px;
        font-size: 1.4vw;
        // line-height: 48px;
        line-height: 2.5vw;
        margin-bottom: 2vw;

        color: #000000;
      }
    }
  }
}

.modus {
  padding-left: 5% !important;
  padding-right: 5% !important;

  .top {
    padding-top: 3vw;
    padding-bottom: 7vw;
    background: #f6f4fb;
    // border-radius: 24px;
    border-radius: 1.2vw;
    top: 0;
    left: 0;
    z-index: -1;

    .tag {
      font-style: normal;
      font-weight: 700;
      // font-size: 20px;
      font-size: 1.05vw;
      color: #6941c6;

      padding: 0.4vw 1vw;
      width: fit-content;

      border: 1.5px dashed #7f56d9;
      border-radius: 1.3vw;
      margin-bottom: 1vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      //   font-size: 36px;
      font-size: 1.87vw;
      text-align: center;

      color: #101828;
      margin-bottom: 1.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 27px;
      font-size: 1.41vw;
      // line-height: 48px;
      line-height: 2.5vw;
      color: #000000;
      margin-bottom: 1.5vw;
    }
  }

  .bottom {
    padding-top: 28vw;
    img {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .about {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    margin-top: 2vw;

    .tag {
      font-style: normal;
      font-weight: 500;
      font-size: 3vw;
      border-radius: 3vw;
      padding: 0 1.5vw;
      margin-bottom: 3vw;
    }

    .top {
      margin-bottom: 1vw;
      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 4.5vw;
        line-height: 4.5vw;
        text-align: center;
        width: 100%;
        margin-bottom: 3vw;
        span {
          color: #6941c6;
        }
      }

      .content {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 3vw;
        line-height: 3.5vw;
        width: 95%;
      }
    }

    .bottom {
      padding-top: 15vw;

      .imgContainer {
        top: 0;
        z-index: 1;
      }

      .leftCol {
        padding-top: 45vw;

        .line {
          top: 7%;
          left: -13%;
          height: 63%;
          // height: 636px;
        }

        img {
          width: 100% !important;
        }
      }

      .rightCol {
        padding-top: 28vw;
        padding-bottom: 2vw;
        padding-left: 3vw;
        padding-right: 3vw;
        background-color: #faf9fd;
        border-radius: 24px;

        .tag {
          font-style: normal;
          font-weight: 500;
          font-size: 3vw;
          color: #6941c6;

          border-radius: 3vw;
          width: fit-content;
          padding: 0 1.5vw;
          margin-bottom: 3vw;
        }

        .header {
          font-style: normal;
          font-weight: 600;
          font-size: 3.5vw;
          margin-bottom: 2vw;
        }

        .text {
          font-style: normal;
          font-weight: 400;
          font-size: 2.5vw;
          line-height: 3vw;
          margin-bottom: 2vw;

          color: #000000;
        }
      }
    }
  }

  .modus {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    .top {
      padding-top: 3vw;
      padding-bottom: 20vw;
      background: #f6f4fb;
      // border-radius: 24px;
      border-radius: 3vw;
      top: 0;
      left: 0;
      z-index: -1;

      .tag {
        font-style: normal;
        font-weight: 700;
        font-size: 3vw;
        
        padding: 0 1.5vw;
        width: fit-content;

        border-radius: 3vw;
        margin-bottom: 3vw;
      }

      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        text-align: center;

        margin-bottom: 1.5vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 2.75vw;
        line-height: 3.5vw;
        margin-bottom: 1.5vw;
        width: 95%;
      }
    }

    .bottom {
      padding-top: 55vw;
      img {
        width: 90%;
      }
    }
  }
}
