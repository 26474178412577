.body {
  padding-left: 7.5%;
  padding-right: 7.5%;

  .leftCol {
    background-color: #f9f9f9;
    border-radius: 0px 0px 0px 1vw;
    width: 40%;
    padding: 3.5vw 4vw;

    .header {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      font-size: 1.9vw;

      color: #101828;

      margin-bottom: 2.5vw;
    }

    .formBox {
      label {
        font-style: normal;
        font-weight: 500;
        // font-size: 14px;
        font-size: 0.75vw;
        color: #344054;
      }

      input {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.9vw;
        color: #667085;
      }

      textarea {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.9vw;
        color: #667085;
        resize: none;
      }
    }

    .btn {
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.85vw;

      color: #ffffff;

      background: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  .rightCol {
    width: 60%;

    .imageTop {
      top: 0;
      height: 35%;
      background: rgba(127, 86, 217, 0.4);
      border-radius: 24px 24px 0px 0px;

      .text {
        font-style: normal;
        font-weight: 700;
        // font-size: 72px;
        font-size: 3.75vw;

        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5%;
    padding-right: 2.5%;

    .leftCol {
      background-color: #f9f9f9;
      border-radius: 0px 0px 0px 1vw;
      width: 100%;
      padding: 4vw 4vw;

      .header {
        font-weight: 600;
        font-size: 3.5vw;

        margin-bottom: 2.5vw;
      }

      .formBox {
        label {
          font-style: normal;
          font-weight: 500;
          font-size: 3vw;
        }

        input {
          font-weight: 400;
          font-size: 3vw;
        }

        textarea {
          font-weight: 400;
          font-size: 3vw;
          resize: none;
        }
      }

      .btn {
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
      }
    }

    .rightCol {
      display: none;
    }
  }
}
