.body1 {
  .top {
    // height: 300px;
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: #fbfbfd;
    padding-left: 5% !important;
    padding-right: 5% !important;

    .processTag {
      // width: 100px;
      width: 5.2vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 15px;
      font-size: 14px;
      font-size: 0.74vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      color: #101828;
      width: 80%;
    }

    .cardBox {
      .card {
        // width: 150px;
        width: 7.7vw;
        // height: 130px;
        height: 6.8vw;

        img {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }

        .text {
          font-weight: 600;
          // font-size: 20px;
          font-size: 1.05vw;
        }
      }
    }
  }
}

.body2 {
  .top {
    // height: 300px;
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: #333333;
    padding-left: 5% !important;
    padding-right: 5% !important;

    .processTag {
      // width: 100px;
      width: 5.2vw;
      color: #ffffff;
      border-color: #ffffff !important;
      border-radius: 15px;
      font-size: 14px;
      font-size: 0.74vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      color: #f3f3f3;
    }

    .cardBox {
      .card {
        // width: 150px;
        width: 7.7vw;
        // height: 130px;
        height: 6.8vw;

        img {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }
      }
    }
  }
}

.body3 {
  .top {
    // height: 300px;
    padding-top: 3vw;
    padding-bottom: 1vw;
    background-color: #f4f1f9;
    padding-left: 5% !important;
    padding-right: 5% !important;

    .processTag {
      // width: 100px;
      width: 5.2vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 15px;
      font-size: 14px;
      font-size: 0.74vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      color: #101828;
      width: 80%;
    }

    .cardBox {
      width: 40%;
      .card {
        // width: 150px;
        width: 7.7vw;
        // height: 130px;
        height: 6.8vw;

        img {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }

        .text {
          font-weight: 600;
          // font-size: 20px;
          font-size: 1.05vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body1 {
    .top {
      // height: 300px;
      padding-top: 7vw;
      padding-bottom: 3vw;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .processTag {
        // width: 100px;
        width: fit-content;
        border-radius: 3vw;
        font-size: 3vw;
        padding: 0 1.5vw;
      }

      .heading {
        font-weight: 600;
        font-size: 4vw;
        width: 80%;
      }

      .cardBox {
        .card {
          width: 7.7vw;
          height: 6.8vw;
          margin-bottom: 4vw;

          img {
            width: 90%;
            height: 90%;
          }

          .text {
            font-weight: 600;
            // font-size: 20px;
            font-size: 1.05vw;
          }
        }
      }
    }
  }

  .body2 {
    .top {
      // height: 300px;
      padding-top: 7vw;
      padding-bottom: 3vw;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .processTag {
        // width: 100px;
        width: fit-content;
        border-radius: 3vw;
        font-size: 3vw;
        padding: 0 1.5vw;
      }

      .heading {
        font-weight: 600;
        font-size: 4vw;
        width: 80%;
      }

      .cardBox {
        .card {
          width: 7.7vw;
          height: 6.8vw;
          margin-bottom: 4vw;

          img {
            width: 90%;
            height: 90%;
          }

          .text {
            font-weight: 600;
            // font-size: 20px;
            font-size: 1.05vw;
          }
        }
      }
    }
  }

  .body3 {
    .top {
      // height: 300px;
      padding-top: 7vw;
      padding-bottom: 3vw;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .processTag {
        // width: 100px;
        width: fit-content;
        border-radius: 3vw;
        font-size: 3vw;
        padding: 0 1.5vw;
      }

      .heading {
        font-weight: 600;
        font-size: 4vw;
        width: 80%;
      }

      .cardBox {
        width: 100%;
        .card {
          width: 7.7vw;
          height: 6.8vw;
          margin-bottom: 4vw;

          img {
            width: 8vw !important;
            height: 8vw !important;
          }

          .text {
            font-weight: 600;
            // font-size: 20px;
            font-size: 1.05vw;
          }
        }
      }
    }
  }
}
