.body {
  margin-top: 3vw;
  padding-left: 5%;
  padding-right: 5%;
  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 20px;
    font-size: 1.045vw;
    color: #6941c6;
    width: fit-content;
    padding: 0 0.8vw;

    border: 1.5px solid #7f56d9;
    border-radius: 0.8vw;
    // border-radius: 24px;
    border-radius: 1.1vw;
    // width: 495px;
  }

  .header {
    font-style: normal;
    font-weight: 700;
    // font-size: 60px;
    font-size: 3.12vw;

    color: #101828;

    margin-bottom: 2vw;
  }

  .text {
    width: 92%;
    font-style: normal;
    font-weight: 400;
    // font-size: 28px;
    font-size: 1.46vw;

    color: #101828;

    margin-bottom: 3vw;
  }

  .btn {
    font-weight: 600;
    // font-size: 18px;
    font-size: 0.95vw;
    color: #ffffff;

    background: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    padding: 1vw 2vw;
    margin-bottom: 5vw;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5%;
    padding-right: 2.5%;
    .tag {
      font-weight: 500;
      font-size: 3vw;
      padding: 0 1vw;
      border-radius: 3vw;
    }

    .header {
      font-weight: 700;
      font-size: 5vw;
      margin-bottom: 4vw;
    }

    .text {
      width: 92%;
      font-weight: 400;
      font-size: 3vw;
      margin-bottom: 5vw;
    }

    .btn {
      font-weight: 600;
      font-size: 3vw;
      border-radius: 1vw;

      padding: 1vw 3vw;
      margin-bottom: 8vw;
    }
  }
}
