.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 2vw;

  .top {
    // height: 50vw;

    .leftCol {
      // padding-top: 3vw;
      .tag {
        // width: 210px;
        width: 10.3vw;
        // font-size: 14px;
        font-size: 0.75vw;
        color: #53389e;
        border: 1.5px solid #53389e;
        border-radius: 15px;
        margin-bottom: 0.82vw;
      }

      .header {
        font-style: normal;
        font-weight: 600;
        // font-size: 60px;
        font-size: 3.1vw;
        // line-height: 72px;
        line-height: 3.75vw;
        color: #101828;
        margin-bottom: 1.6vw;

        span {
          color: #53389e;
        }
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        font-size: 1.35vw;
        color: #475467;
      }

      .btn {
        padding-top: 0.7vw;
        padding-bottom: 0.7vw;
        background: #f9f5ff;

        border: 1px solid #f9f5ff;
        backdrop-filter: blur(5px);

        border-radius: 8px;
        color: #6941c6;
        font-style: normal;
        font-weight: 700;
        // font-size: 17px;
        font-size: 0.9vw;
      }
    }

    .rightCol {
      .positionLeft {
        left: 0;
      }

      .positionRight {
        right: -17%;
      }

      .rightZero {
        right: 0;
      }

      .positionCenter {
        left: "50%", "translateX(-50%)";
      }

      .form {
        // right: 0;
        background: rgba(242, 244, 247, 0.6);
        backdrop-filter: blur(10px);
        border-radius: 24px;
        padding: 1.1vw;
        // width: 60%;

        .formBody {
          background: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(2.5px);
          border-radius: 16px;

          padding: 2vw 1vw;

          img {
            // width: 27px;
            width: 1.4vw;
            // height: 30px;
            height: 1.6vw;
          }

          .header {
            font-style: normal;
            font-weight: 600;
            // font-size: 30px;
            font-size: 1.56vw;

            color: #101828;
          }

          .formBox {
            // width: 75%;

            margin-bottom: 1vw;

            label {
              font-style: normal;
              font-weight: 500;
              // font-size: 14px;
              font-size: 0.73vw;
            }

            input {
              width: 19vw;
              font-style: normal;
              font-weight: 400;
              // font-size: 16px;
              font-size: 0.83vw;

              border: 1px solid #d0d5dd;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 8px;
            }

            .textArea {
              resize: none;
              width: 19vw;
              font-style: normal;
              font-weight: 400;
              // font-size: 16px;
              font-size: 0.83vw;
              height: 7vw;
              border: 1px solid #d0d5dd;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 8px;
            }
          }

          .btn {
            width: 19vw;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            font-size: 0.85vw;
            color: #ffffff;

            background: #7f56d9;
            border: 1px solid #7f56d9;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
          }
        }
      }
    }
  }

  .bottom1 {
    margin-top: 11vw;
    background-color: #1d2939;
    border-radius: 1.7vw;
    // border-radius: 32px;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .header {
      width: 80%;

      color: white;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 44px;
      line-height: 2.3vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      // line-height: 30px;
      line-height: 1.6vw;
      color: #e9d7fe;
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom2 {
    margin-top: 11vw;
    background-color: #f9fafb;
    border-radius: 1.7vw;
    // border-radius: 32px;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .header {
      width: 80%;
      color: #101828;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 44px;
      line-height: 2.3vw;
    }

    .text {
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      // line-height: 30px;
      line-height: 1.6vw;
      color: #101828;
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom3 {
    margin-top: 11vw;
    background-color: #f9fafb;
    border-radius: 1.7vw;
    // border-radius: 32px;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .leftCol {
      width: 65%;
      .header {
        width: 97%;
        color: #101828;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }

      .text {
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 30px;
        line-height: 1.6vw;
        color: #101828;
      }
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom4 {
    margin-top: 11vw;
    background-color: #1d2939;
    border-radius: 1.7vw;
    // border-radius: 32px;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .leftCol {
      width: 65%;
      .header {
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }

      .text {
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 30px;
        line-height: 1.6vw;
        color: #e7e7e7;
      }
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      line-height: 24px;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom5 {
    margin-top: 11vw;
    background-color: #53389e;
    // border-radius: 32px;
    border-radius: 1.7vw;
    // height: 374px;

    padding-top: 3vw;
    padding-bottom: 3vw;

    .leftCol {
      width: 65%;
      .header {
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }

      .text {
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 30px;
        line-height: 1.6vw;
        color: #e7e7e7;
      }
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      line-height: 24px;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom6 {
    margin-top: 11vw;
    background-color: #636f73;
    // border-radius: 32px;
    border-radius: 1.7vw;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .leftCol {
      width: 65%;
      .header {
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }

      .text {
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 30px;
        line-height: 1.6vw;
        color: #e7e7e7;
      }
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      line-height: 24px;

      padding: 0.5vw 1vw;

      background: #262c2d;
      border: 1px solid #636f73;
      /* Shadow/xs */

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .bottom7 {
    margin-top: 11vw;
    background-color: #53389e;
    border-radius: 1.7vw;
    // border-radius: 32px;
    // height: 374px;

    padding-top: 4vw;
    padding-bottom: 4vw;

    .header {
      width: 80%;

      color: white;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      // line-height: 44px;
      line-height: 2.3vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      // line-height: 30px;
      line-height: 1.6vw;
      color: #e9d7fe;
    }

    .btn {
      color: white;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      font-size: 0.85vw;
      background: #7f56d9;

      padding: 0.5vw 1vw;

      border: 1px solid #7f56d9;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
}

.arrow {
  z-index: -1;
  width: 40%;
  left: 30%;
  margin-top: 3vw;
}

@media (max-width: "2800px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 4vw;
  }
}

@media (max-width: "2600px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 4.5vw;
  }
}

@media (max-width: "2400px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 5vw;
  }
}

@media (max-width: "2200px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 5.5vw;
  }
}

@media (max-width: "2000px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 6vw;
  }
}

@media (max-width: "1800px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 6.5vw;
  }
}

@media (max-width: "1600px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 7vw;
  }
}

@media (max-width: "1600px") {
  .arrow {
    width: 40%;
    left: 30%;
    margin-top: 8vw;
  }
}

@media (max-width: "1400px") {
  .arrow {
    width: 39.5%;
    left: 30%;
    margin-top: 9.2vw;
  }
}

@media (max-width: "1200px") {
  .arrow {
    width: 39%;
    left: 30%;
    margin-top: 10.5vw;
  }
}

@media (max-width: "1100px") {
  .arrow {
    width: 38%;
    left: 30%;
    margin-top: 11.8vw;
  }
}

@media (max-width: "1000px") {
  .arrow {
    width: 38%;
    left: 30%;
    margin-top: 12.5vw;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 2vw;

    .top {
      .leftCol {
        // justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        .tag {
          width: fit-content;
          font-size: 3vw;
          padding: 0 1vw;
          border-radius: 3vw;
          margin-bottom: 3vw;
        }

        .header {
          font-weight: 500;
          font-size: 4vw;
          line-height: 5vw;
          color: #101828;
          margin-bottom: 3vw;
          width: 85%;

          span {
            color: #53389e;
          }
        }

        .text {
          font-weight: 300;
          width: 90%;
          font-size: 3vw;
          color: #475467;
        }

        .btn {
          padding: 1vw 3vw;
          border-radius: 2vw;
          font-weight: 700;
          font-size: 3vw;
        }
      }

      .rightCol {
        margin-top: 5vw;
        align-items: center !important;
        .positionLeft {
          display: none !important;
        }

        .positionRight {
          display: none !important;
        }

        .rightZero {
          display: none !important;
        }

        .positionCenter {
          display: none !important;
        }

        .form {
          background: rgba(242, 244, 247, 0.6);
          backdrop-filter: blur(10px);
          border-radius: 3vw;
          padding: 1.1vw;
          width: 100% !important;

          .formBody {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(2.5px);
            border-radius: 3vw;

            padding: 2vw 1vw;
            width: 100% !important;

            img {
              // width: 27px;
              width: 5.4vw;
              // height: 30px;
              height: 5.6vw;
            }

            .header {
              font-size: 4vw;
              margin-top: 2vw;
            }

            .formBox {
              width: 90% !important;

              margin-bottom: 1vw;

              label {
                font-size: 3vw;
              }

              input {
                width: 100% !important;
                font-weight: 400;
                font-size: 3vw;
                border-radius: 1vw;
              }

              .textArea {
                resize: none;
                width: 100% !important;
                font-size: 3vw;
                height: 20vw;

                border-radius: 1vw;
              }
            }

            .btn {
              margin-top: 3vw;
              width: fit-content;
              font-size: 03vw;
              color: #ffffff;

              border-radius: 1vw;
            }
          }
        }
      }
    }

    .bottom1 {
      margin-top: 11vw;
      background-color: #1d2939;
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;

      .header {
        width: 90%;

        font-style: normal;
        font-weight: 500;
        font-size: 3.2vw;
        line-height: 4vw;
        // margin: 0;
        margin-bottom: 2vw;
      }

      .text {
        width: 80%;

        font-weight: 400;
        font-size: 2.7vw;
        line-height: 3vw;
        margin-bottom: 1vw;
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom2 {
      margin-top: 11vw;
      background-color: #f9fafb;
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;

      .header {
        width: 90%;

        font-style: normal;
        font-weight: 500;
        font-size: 3.2vw;
        line-height: 3vw;
        // margin: 0;
        margin-bottom: 1vw;
      }

      .text {
        width: 80%;

        font-weight: 400;
        font-size: 2.7vw;
        line-height: 3vw;
        margin-bottom: 2vw;
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom3 {
      border-radius: 3vw;

      padding-top: 8vw;
      padding-bottom: 5vw;
      display: flex !important;
      flex-direction: column !important;

      .leftCol {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        text-align: center;
        .header {
          width: 90%;

          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 4vw;
          // margin: 0;
          margin-bottom: 2vw;
        }

        .text {
          width: 80%;

          font-weight: 400;
          font-size: 2.7vw;
          line-height: 3vw;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom4 {
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;
      display: flex !important;
      flex-direction: column !important;

      .leftCol {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        text-align: center;
        .header {
          width: 90%;

          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 4vw;
          // margin: 0;
          margin-bottom: 2vw;
        }

        .text {
          width: 80%;

          font-weight: 400;
          font-size: 2.7vw;
          line-height: 3vw;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom5 {
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;
      display: flex !important;
      flex-direction: column !important;

      .leftCol {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        text-align: center;
        .header {
          width: 90%;

          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 4vw;
          // margin: 0;
          margin-bottom: 2vw;
        }

        .text {
          width: 80%;

          font-weight: 400;
          font-size: 2.7vw;
          line-height: 3vw;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom6 {
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;
      display: flex !important;
      flex-direction: column !important;

      .leftCol {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        text-align: center;
        .header {
          width: 90%;

          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 4vw;
          // margin: 0;
          margin-bottom: 2vw;
        }

        .text {
          width: 80%;

          font-weight: 400;
          font-size: 2.7vw;
          line-height: 3vw;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }

    .bottom7 {
      margin-top: 11vw;
      border-radius: 3vw;

      padding-top: 6vw;
      padding-bottom: 6vw;

      .header {
        width: 90%;

        font-style: normal;
        font-weight: 500;
        font-size: 3.2vw;
        line-height: 4vw;
        // margin: 0;
        margin-bottom: 2vw;
      }

      .text {
        width: 80%;

        font-weight: 400;
        font-size: 2.7vw;
        line-height: 3vw;
      }

      .btn {
        font-weight: 500;
        font-size: 3vw;
        padding: 0.5vw 1.5vw;

        border-radius: 2vw;
      }
    }
  }

  .arrow {
    display: none;
  }
}
