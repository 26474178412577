.body {
  margin-bottom: 8vw;
  .menu {
    position: absolute;
    top: 2vw;
    left: 2.2vw;
    right: 2.2vw;
    bottom: 2vw;
    margin: 0;
    z-index: 1000;
    opacity: 0.98;
    pointer-events: none;

    .menuTop {
      transition-duration: 0.1s;
      pointer-events: auto;

      padding: 0.8vw 2vw;

      border-radius: 2vw;

      .menuTitle {
        width: 5vw;
      }

      .menuNavTop {
        .lineLink {
          color: #ffffff !important;
          font-style: normal;
          font-weight: 400;
          font-size: 0.9vw;
          width: fit-content;

          &:hover {
            text-decoration: underline;
          }

          cursor: pointer;

          margin-left: 0.4vw !important;
          margin-right: 0.4vw !important;
        }
      }

      .menuSide {
        .lineLink {
          color: #ffffff !important;
          font-style: normal;
          font-weight: 400;
          font-size: 0.9vw;
          width: fit-content;

          &:hover {
            text-decoration: underline;
          }
          cursor: pointer;
        }

        .menuBar {
          color: #ffffff;
          font-size: 8vw;
        }
      }
    }

    .removeBottomRadius {
      border-radius: 2vw 2vw 0 0;
    }

    .menuContentWrap {
      overflow: hidden;
      width: 100%;
      height: 80vh;

      .menuContent {
        height: 100%;
        border-radius: 0 0 2vw 2vw;
        padding: 2vw;

        translate: none;
        rotate: none;
        scale: none;
        transition-duration: 1s;

        .menuTagline {
          font-size: 5vw !important;
          font-weight: 300 !important;
          color: #ffffff !important;
        }

        .column {
          margin-bottom: 2vw !important;

          .link {
            color: #ffffff !important;
            font-style: normal;
            font-weight: 300;
            // font-size: 16px;
            font-size: 0.8vw;
            width: fit-content;
          }

          .header {
            color: #ffffff !important;
            font-style: normal;
            font-weight: 400;
            // font-size: 16px;
            font-size: 1vw;
            cursor: default;
          }
        }

        .menuBack {
          position: relative;
          grid-area: back;
          height: 90px;
          align-self: start;
          margin-top: calc(5.5vh + 2rem);

          height: 200px;
          margin-top: calc(5.5vh + 1.5rem);
          transition-duration: 0.5s;

          svg {
            display: block;
            fill: #000;
            height: 100%;
            max-height: 201px;
            transition: transform 0.3s;
          }

          &:hover,
          &:focus {
            transition-duration: 0.5s;
            transform: translateY(-10px);
          }
        }

        .unbutton {
          background: none;
          border: 0;
          padding: 0;
          // margin: 0;
          font: inherit;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .menuOpen {
    pointer-events: auto;
  }
}

@media only screen and (min-width: 600px) {
  .body {
    .menu {
      .menuTop {
        .menuSide {
          .menuBar {
            display: none;
          }
        }
      }

      .menuContentWrap {
        .menuContent {
          .columnHider {
            display: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-bottom: 20vw;
    .menu {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .menuTop {
        border-radius: 0;
        .menuTitle {
          width: 75%;
        }

        .menuNavTop {
          .lineLink {
            display: none;
          }
        }

        .menuSide {
          .lineLink {
            display: none;
          }
        }
      }

      .menuContentWrap {
        height: 100vh;

        .menuContent {
          max-height: 90vh;
          min-height: 90vh;
          overflow-y: auto;
          border-radius: 0 !important;
          padding-top: 4vw;

          .menuTag {
            display: none !important;
          }

          .column {
            margin-bottom: 5vw !important;
            padding-left: 5vw;

            .link {
              color: #ffffff !important;
              font-style: normal;
              font-weight: 300;
              // font-size: 16px;
              font-size: 2.5vw;
              width: fit-content;
            }

            .header {
              color: #ffffff !important;
              font-style: normal;
              font-weight: 500;
              // font-size: 16px;
              font-size: 4vw;
              cursor: default;
            }
          }

          .menuBack {
            position: relative;
            grid-area: back;
            height: 90px;
            align-self: start;
            margin-top: calc(5.5vh + 2rem);

            height: 200px;
            margin-top: calc(5.5vh + 1.5rem);
            transition-duration: 0.5s;

            svg {
              display: block;
              fill: #000;
              height: 100%;
              max-height: 201px;
              transition: transform 0.3s;
            }

            &:hover,
            &:focus {
              transition-duration: 0.5s;
              transform: translateY(-10px);
            }
          }

          .unbutton {
            background: none;
            border: 0;
            padding: 0;
            // margin: 0;
            font: inherit;
            cursor: pointer;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
