.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
  padding-top: 8vw;
  padding-bottom: 6vw;
  background-color: #f1eff4;

  .header {
    color: #101828;
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    // font-size: 24px;
    font-size: 1.25vw;
    color: #323d54;
    margin-bottom: 2.5vw;
  }

  .box {
    margin-bottom: 2vw;
    padding: 1vw !important;
    .card {
      background-color: #ffffff;
      border-radius: 0.8vw;
      //   width: 510px;
      width: 26.55vw;
      //   height: 560px;
      height: 28vw;
      padding: 3vw 3vw;

      .absoluteImg {
        left: 0;
        top: 0;
        border-radius: 0.8vw;
        // opacity: 0.5;
      }

      .img {
        // width: 60px;
        width: 3.2vw;
        // height: 60px;
        height: 3.2vw;
      }

      .img2 {
        // width: 49px;
        width: 2.6vw;
        // height: 60px;
        height: 3.2vw;
      }

      .heading {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;
        color: #000000;
        margin-top: 1vw;
      }

      .heading2 {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;
        color: #ffffff;
        margin-top: 1vw;
      }

      .content {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;
        color: #a8a8a8;
        margin-top: 1vw;
      }

      .btn {
        font-weight: 600;
        // font-size: 18px;
        font-size: 0.95vw;

        text-transform: uppercase;

        color: #ffffff;

        padding: 16px 28px;
        padding: 0.9vw 1.2vw;
        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 35px;

        margin-top: 3vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    padding-top: 8vw;
    padding-bottom: 6vw;
    background-color: #f1eff4;

    .header {
      font-weight: 600;
      font-size: 5vw;
      margin-bottom: 2.5vw;
    }

    .text {
      font-weight: 300;
      font-size: 4vw;
      margin-bottom: 3vw;
    }

    .box {
      margin-bottom: 0;
      padding: 1vw !important;
      .card {
        border-radius: 1vw;
        width: 100%;
        height: 100%;
        padding: 3vw 3vw;

        .absoluteImg {
          left: 0;
          top: 0;
          border-radius: 1vw;
          // opacity: 0.5;
        }

        .img {
          width: 7vw;
          height: 7vw;
        }

        .img2 {
          width: 6vw;
          height: 7vw;
        }

        .heading {
          font-weight: 400;
          // font-size: 24px;
          font-size: 3vw;
          margin-top: 2vw;
        }

        .heading2 {
          font-weight: 400;
          font-size: 3vw;
          margin-top: 2vw;
        }

        .content {
          font-style: normal;
          font-weight: 400;
          // font-size: 24px;
          font-size: 2.5vw;
          color: #a8a8a8;
          margin-top: 2vw;
        }

        .btn {
          font-weight: 600;
          font-size: 3vw;


          padding: 0.9vw 1.5vw;
          border-radius: 3vw;

          margin-top: 3vw;
        }
      }
    }
  }
}
