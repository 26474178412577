.body {
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin-top: 8vw;

  .container {
    border-radius: 1.25vw 1.25vw 0px 0px;
    padding-top: 3vw;
    padding-bottom: 2.5vw;

    .header {
      width: 60%;
      font-style: normal;
      font-weight: 600;
      //   font-size: 36px;
      font-size: 1.88vw;
      margin-bottom: 1.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      //   font-size: 24px;
      font-size: 1.25vw;

      margin-bottom: 2vw;
    }

    .btn {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      font-size: 0.85vw;
      color: #ffffff;

      background: #53389e;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      padding: 0.4vw 2vw;
    }
  }

  .bottom {
    border-radius: 1.25vw 1.25vw 1.25vw 1.25vw;
  }
}

.marginBottom {
  margin-bottom: 8vw;
  margin-top: 4vw;
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-top: 8vw;

    .container {
      border-radius: 2vw 2vw 0px 0px;
      padding-top: 5vw;
      padding-bottom: 2.5vw;

      .header {
        width: 85%;
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        margin-bottom: 1.5vw;
      }

      .text {
        width: 85%;
        font-style: normal;
        font-weight: 400;
        font-size: 2.9vw;

        margin-bottom: 2vw;
      }

      .btn {
        font-weight: 600;
        font-size: 3vw;
        padding: 0.5vw 3vw;
        margin-bottom: 2vw;
      }
    }

    .bottom {
      border-radius: 2vw 2vw 2vw 2vw;
    }
  }

  .marginBottom {
    margin-bottom: 8vw;
    margin-top: 4vw;
  }
}
