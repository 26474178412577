.topServices {
  padding-left: 2vw !important;
  padding-right: 2vw !important;

  .body {
    .top {
      background: #53389e;
      border-radius: 32px;
      z-index: -1;
      padding-bottom: 13vw;
      padding-top: 3vw;

      .servicesTag {
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        width: 8.5vw;
        font-style: normal;
        font-weight: 500;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 20px;
        line-height: 1.05vw;
        color: white;
        border: 1.5px dashed white;
        // border-radius: 34px;
        border-radius: 2vw;
      }

      .header {
        color: #ffffff;
        font-style: normal;
        font-weight: 400;
        // font-size: 60px;
        font-size: 2.5vw;
        // line-height: 72px;
        line-height: 3.75vw;

        margin-bottom: 0.5vw;
      }

      .text {
        width: 92%;
        color: white;
        font-style: normal;
        font-weight: 300;
        // font-size: 27px;
        font-size: 1.45vw;
        // line-height: 48px;
        line-height: 2.5vw;

        margin-bottom: 0;
      }
    }

    .bottom {
      img {
        margin-top: 22vw;
        width: 95%;
      }
    }
  }
}

// @media (min-width: 1400px) {
//   .topServices {
//     .body {
//       height: 700px;
//     }
//   }
// }

// @media (min-width: 1600px) {
//   .topServices {
//     .body {
//       height: 750px;
//     }
//   }
// }

// @media (min-width: 1800px) {
//   .topServices {
//     .body {
//       height: 800px;
//     }
//   }
// }

// @media (min-width: 2000px) {
//   .topServices {
//     .body {
//       height: 850px;
//     }
//   }
// }

// @media (min-width: 2200px) {
//   .topServices {
//     .body {
//       height: 900px;
//     }
//   }
// }

// @media (min-width: 2400px) {
//   .topServices {
//     .body {
//       height: 950px;
//     }
//   }
// }

.ourServicesBody {
  padding-left: 10% !important;
  padding-right: 10% !important;

  .body {
    // height: 700px;

    .top {
      // height: 150px;
      margin-top: 3vw;

      .ourServicesTag {
        // width: 105px;
        width: 5.4vw;
        font-style: normal;
        font-weight: 500;
        // font-size: 14px;
        font-size: 0.73vw;
        // line-height: 20px;
        line-height: 1.1vw;
        border: 1.5px dashed #53389e;
        border-radius: 34px;

        color: #53389e;
      }

      .header {
        font-style: normal;
        font-weight: 400;
        // font-size: 36px;
        font-size: 1.87vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }
    }

    .bottom {
      margin-top: 3vw;
      // height: 500px;

      .cardSize {
        background-color: #f9fafb;
        border-radius: 15px;
        padding-left: 1.1vw;
        padding-top: 1.1vw;

        margin-bottom: 1vw;

        .image {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }

        .servicesFont {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          font-size: 0.75vw;
          color: #98a2b3;
        }

        .servicesFontSize {
          font-style: normal;
          font-weight: 400;
          // font-size: 18px;
          font-size: 0.94vw;
          color: #101828;
          margin-right: 1vw;
          margin-bottom: 0;
        }

        .servicesFontWeight {
          font-style: normal;
          font-weight: 700;
          // font-size: 20px;
          font-size: 1.05vw;
          // line-height: 30px;
          line-height: 1.5vw;
          color: #101828;

          margin-top: 0.5vw;
          margin-bottom: 1vw;
        }
      }
    }
  }
}
