.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;

  .top {
    .title {
      color: #475467;
      font-weight: 400;
      font-size: 0.9vw;
      margin-top: 1vw;
    }

    .header {
      font-weight: 400;
      font-size: 2.7vw;
      margin-bottom: 3vw;
    }

    .imgBox {
      width: 70%;
      height: 60vw;
    }
  }

  .mid {
    width: 80%;
    margin-top: 1vw;

    p {
      color: #475467;
      font-weight: 300;
      font-size: 1vw;
      line-height: 2vw;
      margin-bottom: 1vw;
      margin: 1vw 0;
    }
    li {
      color: #475467;
      font-weight: 300;
      font-size: 1vw;
      line-height: 2vw;
    }

    h1 {
      font-weight: 400;
      font-size: 2vw;
      margin: 1.5vw 0;
    }
    h2 {
      font-weight: 300;
      font-size: 1.8vw;
      margin: 1.5vw 0;
    }
    h3 {
      font-weight: 300;
      font-size: 1.5vw;
      margin: 1.5vw 0;
    }
    h4 {
      font-weight: 300;
      font-size: 1.3vw;
      margin: 1.5vw 0;
    }
    h5 {
      font-weight: 300;
      font-size: 1vw;
      margin: 1.5vw 0;
    }
    h6 {
      font-weight: 300;
      font-size: 0.8vw;
      margin: 1.5vw 0;
    }
  }

  .bottom {
    width: 90%;

    .title {
      font-size: 1.3vw;
      font-weight: 500;
      color: #fff;
      // bottom: 0;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      // opacity: 0.8;
      box-shadow: 1px 5px 15px -3px rgba(0, 0, 0, 0.75);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    .top {
      .title {
        color: #475467;
        font-weight: 500;
        font-size: 3.5vw;
        margin-top: 1vw;
      }

      .header {
        font-weight: 700;
        font-size: 5vw;
        margin-bottom: 3vw;
      }

      .imgBox {
        width: 90%;
        height: 80vw;
      }
    }

    .mid {
      width: 95%;
      margin-top: 5vw;

      p {
        color: #475467;
        font-weight: 300;
        font-size: 2.5vw;
        line-height: 3vw;
        margin-bottom: 1vw;
        margin: 1vw 0;
      }
      ol {
        padding-left: 4vw;
        li {
          color: #475467;
          font-weight: 300;
          font-size: 2.5vw;
          line-height: 3vw;
        }
      }

      h1 {
        font-weight: 700;
        font-size: 5vw;
        margin: 1.5vw 0;
      }
      h2 {
        font-weight: 500;
        font-size: 4vw;
        margin: 1.5vw 0;
      }
      h3 {
        font-weight: 400;
        font-size: 3.5vw;
        margin: 1.5vw 0;
      }
      h4 {
        font-weight: 300;
        font-size: 1.3vw;
        margin: 1.5vw 0;
      }
      h5 {
        font-weight: 300;
        font-size: 1vw;
        margin: 1.5vw 0;
      }
      h6 {
        font-weight: 300;
        font-size: 0.8vw;
        margin: 1.5vw 0;
      }
    }

    .bottom {
      width: 100%;

      .title {
        font-size: 2.5vw;
        font-weight: 500;
        color: #fff;
        // bottom: 0;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        // opacity: 0.8;
        box-shadow: 1px 5px 15px -3px rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #overlay {
      position: absolute;
      top: 0;
      left: 0;
      clear: float;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      color: #ffffff;
    }
  }
}
