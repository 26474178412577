.body {
  padding-top: 4.5vw;
  padding-bottom: 4vw;
  // height: 580px;

  .imgBorder1 {
    // border: 4px solid #101828;
    // border-left: 0px;
    // border-radius: 0px 10px 10px 0px;
    object-fit: fill;
  }

  .imgBorder2 {
    // border: 4px solid #101828;
    // border-right: 0px;
    // border-radius: 10px 0px 0px 10px;
    object-fit: fill;
  }

  .container {
    padding-left: 4vw;
    padding-right: 6.5vw;
    .tag {
      // width: 100px;
      width: 5.7vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 15px;
      // font-size: 14px;
      font-size: 0.75vw;
    }

    .header {
      font-style: normal;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      color: #101828;
    }

    .line {
      height: 2px;
      margin-top: 3vw;
      margin-bottom: 1.5vw;
    }

    .imgSize {
      // width: 48px;
      width: 2.5vw;
      // height: 48px;
      height: 2.5vw;
    }

    .text {
      font-style: normal;
      font-weight: 600;
      // font-size: 20px;
      font-size: 1.05vw;
      line-height: 30px;
      color: #101828;
    }
  }
}

.paddingRight {
  padding-right: 5%;
}

.paddingLeft {
  padding-left: 5%;
}

@media only screen and (max-width: 600px) {
  .body {
    padding-top: 4.5vw;
    padding-bottom: 4vw;
    // height: 580px;

    .imgBorder1 {
      display: none;
    }

    .imgBorder2 {
      display: none;
    }

    .container {
      padding-left: 4vw;
      padding-right: 6.5vw;
      width: 100%;
      align-items: center !important;
      .tag {
        padding: 0vw 1.5vw;
        width: fit-content;
        border-radius: 3vw;
        font-size: 3vw;
      }

      .header {
        font-weight: 500;
        font-size: 4vw;
      }

      .box {
        width: 100%;
        .line {
          height: 2px;
          margin-top: 3vw;
          margin-bottom: 1.5vw;
        }

        .imgSize {
          width: 6vw;
          height: 6vw;
        }

        .text {
          font-weight: 500;
          font-size: 3vw;
          line-height: 8vw;
        }
      }
    }
  }
}
