.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin-top: 6vw;

  .portfolioTag {
    // width: 80px;
    width: fit-content;
    padding: 0 0.5vw;
    font-style: normal;
    font-weight: 400;
    // font-size: 14px;
    font-size: 0.75vw;
    color: #6941c6;
    border-color: #6941c6 !important;
    border-radius: 0.8vw;
    line-height: 1.2vw;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    // font-size: 36px;
    font-size: 1.9vw;
    margin-bottom: 2vw;
  }

  .spaceEvenly {
    justify-content: space-evenly !important;
    .portfolioCard {
      width: 22vw;
      height: 22.5vw;
      background: #101828;
      box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);

      .bottomStrip {
        opacity: 0.7;
      }

      .portfolioName {
        font-weight: 500;
        font-style: normal;
        // font-size: 24px;
        font-size: 1.3vw;
        color: #212529 !important;
        margin-bottom: 0.4vw;
      }

      .portfolioDesig {
        font-style: normal;
        font-weight: 500;
        // font-size: 16px;
        font-size: 0.9vw;
        color: #636f73;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 5% !important;
    padding-right: 5% !important;
    margin-top: 6vw;

    .portfolioTag {
      width: fit-content;
      padding: 1.2vw 1.5vw;
      font-style: normal;
      font-weight: 400;
      font-size: 3vw;
      border-radius: 2vw;
    }

    .header {
      font-weight: 500;
      font-size: 5vw;
      margin-bottom: 2vw;
    }

    .spaceEvenly {
      .portfolioCard {
        width: 90vw;
        height: 90.5vw;
        // border: 1px solid black;
        // background: none;
        // box-shadow: none;
        margin-bottom: 5vw;

        .bottomStrip {
          opacity: 0.7;
        }

        .portfolioName {
          font-weight: 400;
          font-size: 7vw;
        }

        .portfolioDesig {
          font-weight: 400;
          font-size: 4vw;
        }
      }
    }
  }
}
