.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 4vw;

  .rowContainer {
    margin-bottom: 8vw;
    .leftCol1 {
      padding-top: 3vw;
      .tag {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        font-size: 0.95vw;

        color: #6941c6;
        border: 1px solid #6941c6 !important;
        border-radius: 0.8vw;

        width: fit-content;
        padding: 0 0.5vw;
        margin-bottom: 1vw;
      }

      .header {
        font-style: normal;
        font-weight: 700;
        // font-size: 60px;
        font-size: 3.15vw;

        color: #101828;
        margin-bottom: 2vw;

        span {
          color: #53389e;
        }
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 28px;
        font-size: 1.46vw;
        // line-height: 48px;
        line-height: 2.5vw;
        color: #101828;
        margin-bottom: 3vw;
      }

      .botton {
        font-style: normal;
        font-weight: 600;
        // font-size: 18px;
        font-size: 0.95vw;
        text-transform: uppercase;
        color: #ffffff;

        padding: 0.7vw 1.2vw;

        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.4vw;
      }
    }

    .leftCol2 {
      img {
        width: 90%;
      }
    }

    .rightCol2 {
      padding-right: 4vw;
      padding-top: 4vw;
      .heading {
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        color: #101828;
        margin-bottom: 1.5vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 26px;
        font-size: 1.355vw;
        line-height: 2.05vw;
        color: #5d697a;
        margin-bottom: 1.5vw;
      }
    }

    .img {
      margin-top: 0.3vw;
      // width: 28px;
      width: 1.48vw;
      // height: 28px;
      height: 1.48vw;
    }

    .content {
      padding-left: 0.7vw;
      padding-right: 3vw;
      font-style: normal;
      font-weight: 500;
      // font-size: 25px;
      font-size: 1.3vw;
      // line-height: 42px;
      line-height: 2.2vw;

      color: #101828;
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    padding-top: 0;

    .rowContainer {
      margin-bottom: 8vw;
      .leftCol1 {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tag {
          font-style: normal;
          font-weight: 500;
          font-size: 3vw;

          border-radius: 3vw;

          width: fit-content;
          padding: 0 1.5vw;
          margin-bottom: 3vw;
        }

        .header {
          font-size: 5vw;

          margin-bottom: 2vw;

          span {
            color: #53389e;
          }
        }

        .text {
          text-align: center;
          font-weight: 400;
          font-size: 3vw;
          line-height: 4vw;
          margin-bottom: 4vw;
        }

        .botton {
          font-size: 3vw;

          padding: 0.7vw 3vw;

          border-radius: 1vw;
        }
      }

      .rightCol1 {
        display: none;
      }

      .leftCol2 {
        img {
          width: 90%;
        }
        padding-right: 0;
      }

      .rightCol2 {
        padding-right: 4vw;
        padding-left: 0;
        padding-top: 4vw;
        .heading {
          font-style: normal;
          font-weight: 600;
          // font-size: 36px;
          font-size: 3.5vw;
          color: #101828;
          margin-bottom: 1.5vw;
        }

        .text {
          font-style: normal;
          font-weight: 300;
          font-size: 2.5vw;
          line-height: 3vw;
          margin-bottom: 1.5vw;
        }
      }

      .img {
        margin-top: 0vw;
        width: 4vw;
        height: 4vw;
      }

      .content {
        padding-left: 1vw;
        padding-right: 0;
        font-weight: 400;
        font-size: 3vw;
        line-height: 4vw;
      }
    }
  }
}
