.startemBody {
  // padding-left: 5% !important;
  // padding-right: 5% !important;
  margin-top: 7vw;

  .imageContainer {
    z-index: -1;
    // padding-top: 240px;
    top: 12%;
  }

  .topBody {
    width: 70%;
    .startemTag {
      // width: 105px;
      width: fit-content;
      padding: 0 0.5vw;

      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 0.8vw;
      font-weight: 400;
      // font-size: 14px;
      font-size: 0.75vw;

      // line-height: 20px;
      line-height: 1.4vw;
      margin-bottom: 0.9vw;
    }

    .header {
      color: #101828;
      font-style: normal;
      font-weight: 500;
      // font-size: 36px;
      font-size: 1.8vw;
      // line-height: 44px;
      line-height: 2.3vw;
      margin-bottom: 1vw;
    }

    .text {
      color: #475467;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1vw;
      width: 71.5%;
      line-height: 1.6vw;
      // line-height: 30px;
      margin-bottom: 5vw;
    }

    .imgBorder {
      border-width: 3px !important;
      border-radius: 15px;
      width: 60%;
      margin-bottom: 5vw;

      background: #101828;
      box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
    }
  }

  .midBody {
    padding-left: 5% !important;
    padding-right: 5% !important;
    .startemTopBorder {
      border-top-width: 3px;
      border-top-style: solid;
      border-color: #d0d5dd;
      padding-top: 1.5vw;

      // display: flex;
      // flex-direction: column;
      // align-items: center;

      .header {
        color: #101828;
        font-style: normal;
        font-weight: 500;
        // font-size: 20px;
        font-size: 1.1vw;
        // line-height: 30px;
        line-height: 1.5vw;
        width: 85%;
        margin-bottom: 1.8vw;
      }

      .text {
        color: #475467;
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.85vw;
        // line-height: 28px;
        line-height: 1.46vw;
        width: 85%;
      }
    }
    .startemTopActive {
      border-color: #7f56d9 !important;
    }
  }

  .bottom {
    padding-left: 5% !important;
    padding-right: 5% !important;
    width: 100%;
    margin-top: 5vw;
    .bottomBody {
      background-color: #53389e;
      border-radius: 0.8vw;
      padding: 2.5vw 1vw;

      .header {
        font-style: normal;
        font-weight: 500;
        // font-size: 36px;
        font-size: 1.75vw;
        // line-height: 44px;
        line-height: 2.2vw;
        margin-bottom: 1vw;
      }

      .text {
        color: #e9d7fe;
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 1vw;
        // line-height: 30px;
        line-height: 1.7vw;
      }

      .btn1 {
        color: #344054;
        font-style: normal;
        font-weight: 500;
        // font-size: 16px;
        font-size: 0.85vw;
        // line-height: 24px;
        line-height: 1.2vw;
        background-color: white;

        border: 1px solid #d0d5dd;

        padding: 0.5vw 1vw;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
        margin-right: 0.5vw;
      }

      .btn2 {
        color: white;
        font-style: normal;
        font-weight: 500;
        // font-size: 16px;
        font-size: 0.85vw;
        // line-height: 24px;
        line-height: 1.2vw;
        background-color: #7f56d9;

        border: 1px solid #7f56d9;

        padding: 0.5vw 1vw;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
      }
    }
  }
}

.ourServicesBody {
  padding-left: 10% !important;
  padding-right: 10% !important;
  margin-top: 6vw;

  .body {
    // height: 860px;
    // height: 44.5vw;
    background-color: #1d2939;
    border-radius: 48px;
    padding-bottom: 3vw;
    padding-left: 5vw;
    padding-right: 5vw;

    .top {
      // height: 150px;
      margin-top: 3vw;
      .ourServicesTag {
        // width: 105px;
        width: fit-content;
        padding: 0 0.5vw;
        font-style: normal;
        font-weight: 500;
        // font-size: 14px;
        font-size: 0.73vw;
        // line-height: 20px;
        line-height: 1.1vw;
        border-color: white !important;
        border-radius: 15px;
      }

      .header {
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.87vw;
        // line-height: 44px;
        line-height: 2.3vw;
      }
    }

    .bottom {
      // height: 500px;
      // margin-top: 60px;
      margin-top: 3vw;

      .box {
        margin-bottom: 1vw;
        .image {
          // width: 48px;
          width: 2.5vw;
          // height: 48px;
          height: 2.5vw;
        }

        .servicesFont {
          font-style: normal;
          font-weight: 600;
          // font-size: 14px;
          font-size: 0.75vw;
          color: #ccc;
        }

        .servicesFontSize {
          font-style: normal;
          font-weight: 400;
          // font-size: 18px;
          font-size: 0.94vw;
          color: #eaecf0;
          margin-right: 0.5vw;
          margin-bottom: 0;
        }

        .servicesFontWeight {
          font-style: normal;
          font-weight: 500;
          // font-size: 20px;
          font-size: 1.05vw;
          color: #eaecf0;
          text-decoration: none;
          // line-height: 30px;
          line-height: 1.5vw;
          margin-bottom: 0.4vw;
        }

        .list {
          margin-top: 0.5vw;
        }
      }
    }
  }
}

.processBody {
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin-top: 5vw;

  .processTag {
    width: fit-content;
    padding: 0 0.5vw;
    // font-size: 14px;
    font-size: 0.75vw;
    color: #6941c6;
    border-color: #6941c6 !important;
    border: 1px solid;
    border-radius: 0.75vw;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    // font-size: 36px;
    font-size: 1.8vw;
    // line-height: 48px;
    line-height: 2.5vw;

    margin-bottom: 2vw;
  }

  .transform {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-style: normal;
    font-weight: 500;
    // font-size: 24px;
    font-size: 1.2vw;
    // line-height: 48px;
    line-height: 2.4vw;
  }
}

@media only screen and (max-width: 600px) {
  .startemBody {
    .topBody {
      width: 100%;
      .startemTag {
        width: fit-content;
        padding: 1vw 1vw;

        border-radius: 3vw;
        font-size: 3vw;

        margin-bottom: 4vw;
      }

      .header {
        font-size: 5vw;
        line-height: 2.3vw;
        margin-bottom: 4vw;
      }

      .text {
        width: 90%;
        font-size: 2.5vw;
        line-height: 3vw;
        margin-bottom: 5vw;
      }

      .imgBorder {
        border-width: 0.5vw !important;
        border-radius: 2vw;
        width: 90%;
        height: 100%;
        margin-bottom: 5vw;
      }
    }

    .midBody {
      .startemTopBorder {
        border-top-width: 0.5vw;
        padding-top: 2.5vw;
        padding-left: 0;
        padding-right: 0;

        .header {
          color: #101828;
          font-style: normal;
          font-size: 4vw;
          line-height: 3vw;
          // width: 85%;
          margin-bottom: 2.5vw;
        }

        .text {
          padding-left: 1vw;
          font-size: 2.6vw;
          font-weight: 400;
          line-height: 3vw;
          width: 100%;
        }
      }
      .startemTopActive {
        border-color: #7f56d9 !important;
      }
    }

    .bottom {
      padding-left: 5% !important;
      padding-right: 5% !important;
      margin-top: 5vw;
      .bottomBody {
        width: 100%;
        background-color: #53389e;
        border-radius: 3vw;
        padding: 5vw 1vw;
        .box {
          align-items: center !important;
          margin-bottom: 4vw;

          .header {
            font-weight: 500;
            font-size: 3.5vw;
            margin-bottom: 2.5vw;
          }

          .text {
            font-weight: 400;
            font-size: 2.5vw;
          }
        }

        .btn1 {
          font-weight: 300;
          font-size: 2.8vw;
          width: fit-content;
          padding: 1.5vw 2vw;
          border-radius: 1vw;
          margin-right: 2vw;
        }

        .btn2 {
          font-weight: 300;
          font-size: 2.8vw;
          width: fit-content;

          padding: 1.5vw 2vw;
          border-radius: 1vw;
        }
      }
    }
  }

  .ourServicesBody {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    margin-top: 6vw;

    .body {
      border-radius: 7vw;
      padding-top: 5vw;
      padding-bottom: 3vw;
      // justify-content: center !important;

      .top {
        align-items: center !important;
        .ourServicesTag {
          width: fit-content;
          padding: 1vw 1.5vw;
          font-weight: 500;
          font-size: 3vw;
          line-height: 2.5vw;
          border-radius: 3vw;
        }

        .header {
          font-style: normal;
          font-weight: 400;
          font-size: 4vw;
          width: 85%;
          line-height: 5vw;
        }
      }

      .bottom {
        // height: 500px;
        // margin-top: 60px;
        margin-top: 3vw;

        .box {
          margin-bottom: 3vw;

          .unCenterIcon {
            padding-left: 0 !important;
          }

          .image {
            width: 5vw;
            height: 5vw;
          }

          .servicesFont {
            font-style: normal;
            font-weight: 300;
            font-size: 2.5vw;
            color: #ccc;
          }

          .servicesFontSize {
            font-style: normal;
            font-weight: 400;
            font-size: 3vw;
            color: #eaecf0;
            margin-right: 1vw;
            margin-bottom: 0;
            display: none;
          }

          .servicesFontWeight {
            font-style: normal;
            font-weight: 400;
            font-size: 2.7vw;
            line-height: 4vw;
            margin-bottom: 0.5vw;
          }

          .list {
            margin-top: 0.5vw;
          }
        }
      }
    }
  }

  .processBody {
    padding-left: 5% !important;
    padding-right: 5% !important;
    margin-top: 5vw;

    .processTag {
      width: fit-content;
      padding: 0 1.5vw;
      font-size: 3vw;
      border: 1px solid;
      border-radius: 3vw;
    }

    .header {
      font-style: normal;
      font-weight: 500;
      font-size: 4vw;
      width: 90%;
      line-height: 5.5vw;
      margin-bottom: 4vw;
    }

    .transform {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-weight: 500;
      font-size: 3vw;
      line-height: 4vw;
    }
  }
}
