.body {
  background-color: #53389e;
  margin-top: 5vw;
  .innerBody {
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 3vw;

    .top {
      .leftCol {
        p {
          font-style: normal;
          font-weight: 400;
          // font-size: 16px;
          font-size: 0.7vw;
          width: 42%;
          color: #eaecf0;
        }

        img {
          // width: 130px;
          width: 6.75vw;
          // height: 28px;
          height: 1.45vw;
          margin-bottom: 0.8vw;
        }
      }

      .footerList {
        .footerHeading {
          font-style: normal;
          font-weight: 400;
          // font-size: 14px;
          font-size: 1vw;
          color: #d0d5dd;
          margin-bottom: 1vw;
        }

        .footerHeading2 {
          font-style: normal;
          font-weight: 400;
          // font-size: 14px;
          font-size: 1vw;
          color: #d0d5dd;
          margin-bottom: 1vw;
        }

        .footerText {
          font-style: normal;
          font-weight: 300;
          font-size: 0.8vw;
          line-height: 1.4vw;
          width: fit-content;
          color: #eaecf0;
        }

        .icon {
          color: #ebe5fa;

          img {
            width: 1.8vw;
            height: 1.8vw;
          }
        }
      }

      .rightCol {
        .footerHeading {
          font-style: normal;
          font-weight: 400;
          // font-size: 14px;
          font-size: 1.1vw;
          color: #d0d5dd;
          // line-height: 0;
          margin-bottom: 0.4vw;
        }

        input {
          font-style: normal;
          font-weight: 400;
          // font-size: 16px;
          font-size: 0.85vw;
          width: 65%;

          color: #667085;
          background: #ffffff;
          border: 1px solid #d0d5dd;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
        }

        button {
          font-style: normal;
          font-weight: 600;
          // font-size: 16px;
          font-size: 0.85vw;
          width: 30%;

          background-color: #7f56d9;

          color: #ffffff;
          border: 1px solid #7f56d9;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
      }
    }

    .bottom {
      margin-top: 1vw;
      margin-bottom: 0.5vw;
      .footerText {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.85vw;

        color: #d0d5dd;
      }

      .marginSet {
        margin-right: 1vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 5vw;
    .innerBody {
      padding-left: 5% !important;
      padding-right: 5% !important;
      padding-top: 3vw;

      .top {
        .leftCol {
          p {
            font-style: normal;
            font-weight: 300;
            font-size: 2.5vw;
            width: 100%;
            color: #eaecf0;
          }

          img {
            width: 15vw;
            height: 5vw;
            margin-bottom: 0.8vw;
          }
        }

        .footerList {

          .footerHeading {
            font-style: normal;
            font-weight: 600;
            font-size: 2.5vw;
            color: #EBE5FA;
            margin-bottom: 1vw;
          }

          .footerHeading2 {
            font-style: normal;
            font-weight: 600;
            font-size: 4vw;
            color: #EBE5FA;
            margin-bottom: 1vw;
          }

          .footerText {
            font-style: normal;
            font-weight: 400;
            font-size: 2.7vw;
            line-height: 5vw;
            width: fit-content;
            color: #EBE5FA;
          }

          .icon {
            color: #98a2b3;
            width: 100% !important;

            img {
              width: 3.5vw;
              height: 3.5vw;
            }
          }
        }

        .rightCol {
          .footerHeading {
            font-style: normal;
            font-weight: 400;
            font-size: 3vw;
            margin-bottom: 1.5vw;
          }

          input {
            font-style: normal;
            font-weight: 400;
            font-size: 2.5vw;
            width: 65%;
          }

          button {
            font-style: normal;
            font-weight: 600;
            font-size: 2.5vw;
            width: 30%;
          }
        }
      }

      .bottom {
        margin-top: 3vw;
        margin-bottom: 1vw;

        .footerText {
          font-style: normal;
          font-weight: 400;
          font-size: 2.5vw;
        }

        .marginSet {
          margin-right: 1vw;
        }
      }
    }
  }
}
