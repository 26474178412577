.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;

  .top {
    padding-top: 3vw;
    padding-bottom: 7vw;
    background: #53389e;
    // border-radius: 24px;
    border-radius: 1.2vw;
    top: 0;
    left: 0;
    z-index: -1;

    .header {
      font-style: normal;
      font-weight: 600;
    //   font-size: 36px;
      font-size: 1.87vw;
      text-align: center;

      color: #ffffff;
      margin-bottom: 1vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
    //   font-size: 20px;
      font-size: 1.05vw;
    //   line-height: 30px;
      line-height: 1.55vw;
      color: #e9d7fe;
      margin-bottom: 1vw;
    }

    .btn {
        margin-top: 1vw;
      font-style: normal;
      font-weight: 700;
    //   font-size: 16px;
      font-size: 0.85vw;
      color: #ffffff;

      padding: 0.5vw 3vw;

      background: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0.5vw;
    }
  }

  .bottom {
    padding-top: 23vw;
    img {
      width: 80%;
    }
  }
}
