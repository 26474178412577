.body {
  margin-top: 8vw;
  .top {
    padding-top: 5vw;
    padding-bottom: 8vw;
    background-color: #101828;
    z-index: -1;

    .tag {
      font-style: normal;
      font-weight: 600;
      //   font-size: 16px;
      font-size: 0.85vw;
      color: #dbe0e9;
      margin-bottom: 0.8vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      //   font-size: 48px;
      font-size: 2.5vw;

      color: #ffffff;

      margin-bottom: 1.2vw;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      //   font-size: 20px;
      font-size: 1.05vw;
      color: #dbe0e9;
      margin-bottom: 3vw;
    }
  }

  .bottom {
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 22vw;

    .container {
      width: 75%;

      .box {
        background: #ffffff;

        border: 1px solid #eaecf0;

        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 0.9vw;

        padding: 2vw 2vw;

        // width: 384px;
        width: 20vw;
        // height: 574px;
        // height: 30vw;

        .tag {
          font-style: normal;
          font-weight: 500;
          //   font-size: 14px;
          font-size: 0.75vw;
          color: #6941c6;
          //   line-height: 20px;
          line-height: 1.05vw;

          padding: 0.1vw 1vw;

          background: #f9f5ff;
          mix-blend-mode: multiply;
          border-radius: 0.85vw;
          width: fit-content;

          margin-bottom: 1.5vw;
        }

        .header {
          font-style: normal;
          font-weight: 600;
          //   font-size: 48px;
          font-size: 2.5vw;

          color: #101828;

          margin-bottom: 0.5vw;
        }

        .text {
          font-style: normal;
          font-weight: 400;
          //   font-size: 16px;
          font-size: 0.85vw;
          color: #475467;
        }

        .innerBox {
          border-top: 1px solid #eaecf0;
          border-bottom: 1px solid #eaecf0;

          padding-top: 2vw;
          padding-bottom: 0.5vw;
          .card {
            .content {
              font-style: normal;
              font-weight: 400;
              // font-size: 16px;
              font-size: 0.85vw;

              color: #475467;
              margin-left: 0.5vw;
              margin-bottom: 1vw;
            }
          }
        }

        .btn {
          margin-top: 1.5vw;
          font-style: normal;
          font-weight: 600;
          //   font-size: 16px;
          font-size: 0.85vw;

          color: #ffffff;

          background: #7f56d9;
          border: 1px solid #7f56d9;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 0.425vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 8vw;

    .top {
      padding-top: 5vw;
      padding-bottom: 20vw;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      background-color: #101828;
      z-index: -1;

      .tag {
        font-weight: 600;
        font-size: 2.8vw;
      }

      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        margin-bottom: 1vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 3vw;
        color: #dbe0e9;
        margin-bottom: 3vw;
      }

      .box {
        width: 75% !important;
      }
    }

    .bottom {
      padding-left: 2.5%;
      padding-right: 2.5%;
      padding-top: 45vw;

      .container {
        width: 100%;

        .box {
          background: #ffffff;

          border: 1px solid #eaecf0;

          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
            0px 4px 6px -2px rgba(16, 24, 40, 0.03);
          border-radius: 0.9vw;

          padding: 4vw 1vw;

          // width: 384px;
          width: 32%;
          // height: 574px;
          // height: 30vw;

          .tag {
            font-style: normal;
            font-weight: 700;
            font-size: 2vw;
            line-height: 1.05vw;
            padding: 0;

            background: #f9f5ff;
            mix-blend-mode: multiply;
            width: fit-content;

            margin-bottom: 1.5vw;
          }

          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 4vw;
            margin-bottom: 0.5vw;
          }

          .text {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6vw;
          }

          .innerBox {
            border-top: 1px solid #eaecf0;
            border-bottom: 1px solid #eaecf0;

            padding-top: 2vw;
            padding-bottom: 0.5vw;
            .card {
              .content {
                font-style: normal;
                font-weight: 400;
                font-size: 1.5vw;
                color: #475467;
                margin-left: 0.5vw;
                margin-bottom: 0;
              }
              margin-bottom: 1;
            }
          }

          .btn {
            margin-top: 1.5vw;
            font-style: normal;
            font-weight: 600;
            font-size: 3vw;
            border-radius: 1vw;
          }
        }
      }
    }
  }
}
