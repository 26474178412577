.body {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
  margin-top: 6vw;
  .container1 {
    border-radius: 1.5vw;

    .box1 {
      left: 0;
      border-radius: 1.5vw 0 0 0;
      // width: 130px;
      width: 7vw;
      // height: 110px;
      height: 5.9vw;
    }

    .box2 {
      bottom: 0;
      right: 0;
      // width: 130px;
      width: 9vw;
      // height: 110px;
      height: 5.9vw;
    }

    .header {
      font-style: normal;
      font-weight: 600;
      //   font-size: 36px;
      font-size: 1.9vw;
      margin-top: 3vw;
    }

    .text1 {
      font-style: normal;
      font-weight: 400;
      //   font-size: 24px;
      font-size: 1.25vw;
      margin-top: 1.5vw;
      width: 86%;
    }

    .text2 {
      font-style: normal;
      font-weight: 400;
      //   font-size: 24px;
      font-size: 1.25vw;
      margin-top: 1vw;
      width: 86%;
    }

    .box {
      width: 90%;
      margin-top: 2vw;
      text-align: left;
      .card {
        margin-bottom: 2vw;
        p {
          font-style: normal;
          font-weight: 400;
          //   font-size: 24px;
          font-size: 1.25vw;

          img {
            width: 1.1vw;
            height: 1.1vw;
            margin-right: 0.5vw;
          }
        }
      }

      button {
        width: 75%;
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: 0.9vw;
        text-transform: uppercase;
        color: #ffffff;

        background: #7f56d9;

        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 0.5vw;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
      }
    }

    .btn {
      font-style: normal;
      font-weight: 700;
      //   font-size: 16px;
      font-size: 0.9vw;
      color: #ffffff;
      background: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0.5vw;

      padding: 0.5vw 3vw;

      margin-top: 1vw;
      margin-bottom: 3vw;
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
    margin-top: 6vw;
    .container1 {
      border-radius: 3vw;
      padding-top: 8vw;
      padding-bottom: 3vw;

      .box1 {
        left: 0;
        top: 0;
        border-radius: 3vw 0 0 0;
        // width: 130px;
        width: 15vw;
        // height: 110px;
        height: 10vw;
      }

      .box2 {
        bottom: 0;
        right: 0;
        // width: 130px;
        width: 14vw;
        // height: 110px;
        height: 11vw;
      }

      .header {
        font-style: normal;
        font-weight: 700;
        //   font-size: 36px;
        font-size: 3vw;
        margin-top: 3vw;
      }

      .text1 {
        font-style: normal;
        font-weight: 400;
        //   font-size: 24px;
        font-size: 2.5vw;
        margin-top: 1.5vw;
        width: 95%;
      }

      .text2 {
        font-style: normal;
        font-weight: 400;
        //   font-size: 24px;
        font-size: 2.5vw;
        margin-top: 1vw;
        width: 95%;
      }

      .box {
        width: 95%;
        margin-top: 2vw;
        text-align: left;
        justify-content: center !important;
        .card {
          margin-bottom: 2vw;
          p {
            font-style: normal;
            font-weight: 400;
            //   font-size: 24px;
            font-size: 2.5vw;

            img {
              width: 3vw;
              height: 3vw;
              margin-right: 1vw;
            }
          }
        }

        button {
          width: 100%;
          font-weight: 700;
          // font-size: 16px;
          font-size: 3vw;
          text-transform: uppercase;
          border-radius: 0.5vw;
          padding: 1vw 5vw;
          margin-bottom: 4vw;
        }
      }

      .btn {
        font-weight: 700;
        font-size: 3vw;
        border-radius: 1vw;

        padding: 0.5vw 4vw;

        margin-top: 1vw;
        margin-bottom: 5vw;
      }
    }
  }
}
