.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin-top: 4vw;

  .contactTag {
    width: fit-content;
    padding: 0.05vw 0.5vw;
    color: #6941c6;
    border-color: #6941c6 !important;
    border-radius: 0.8vw;
    font-style: normal;
    font-weight: 500;
    // font-size: 14px;
    font-size: 0.7vw;
    margin-bottom: 0.8vw;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    font-size: 1.9vw;
    color: #101828;
    margin-bottom: 1vw;
  }

  .text {
    color: #475467;
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    font-size: 1vw;
    margin-bottom: 1vw;
  }

  .img {
    margin-bottom: 3vw;
  }

  .box {
    .featuredBg {
      background-color: #f9fafb;
      // height: 170px;
      // height: 10vw;
      padding-top: 1vw;
      padding-left: 1.5vw;

      img {
        // width: 48px;
        width: 2.5vw;
        // height: 48px;
        height: 2.5vw;
      }

      .card {
        padding-left: 1vw;
        .text1 {
          color: #101828;
          font-style: normal;
          font-weight: 600;
          // font-size: 20px;
          font-size: 1.1vw;
        }

        .text2 {
          color: #475467;
          font-style: normal;
          font-weight: 400;
          // font-size: 16px;
          font-size: 0.85vw;
        }

        .text3 {
          color: #6941c6;
          font-style: normal;
          font-weight: 600;
          // font-size: 16px;
          font-size: 0.85vw;
          width: 60%;
        }
      }
    }
  }

  .labelText {
    color: #344054;
    font-style: normal;
    font-weight: 500;
    // font-size: 14px;
    font-size: 0.8vw;
  }

  .inputGroup {
    button,
    a {
      font-weight: 400;
      // font-size: 14px;
      font-size: 0.9vw;
      background-color: white !important;
      border-color: #ced4da !important;
      color: #495057 !important;
      &:focus {
        // border-color: #6941c6 !important;
        // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #6941c6;
        box-shadow: none !important;
        outline: 0 none;
      }
    }
  }

  input {
    font-weight: 400;
    // font-size: 14px;
    font-size: 0.9vw;

    &:focus {
      border-color: #6941c6;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #6941c6;
      outline: 0 none;
    }
  }

  textarea {
    font-weight: 400;
    // font-size: 14px;
    font-size: 0.9vw;
    &:focus {
      border-color: #6941c6;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #6941c6;
      outline: 0 none;
    }
    resize: none;
  }

  .checkBoxGroup {
    input {
      accent-color: #6941c6;
      &:focus {
        // border-color: #6941c6 !important;
        // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #6941c6;
        box-shadow: none !important;
        outline: 0 none;
      }
    }

    .checkBoxIcon {
      height: 1vw;
      width: 1vw;
      margin-right: 0.5vw;
    }

    .checkBox {
      color: #475467;
      font-style: normal;
      font-weight: 400;
      // font-size: 16px;
      font-size: 0.85vw;
      line-height: 0;
      .underline {
        text-decoration: underline;
      }
    }
  }

  .button {
    // width: 130px;
    width: 6.8vw;
    // height: 48px;
    height: 2.5vw;
    color: white;
    background-color: #7f56d9;
    // border: 1px solid;
    border-radius: 0.4vw;
    font-style: normal;
    font-weight: 500;
    // font-size: 16px;
    font-size: 0.85vw;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 6vw;

    .contactTag {
      width: fit-content;
      padding: 0 1.4vw;
      border-radius: 3vw;
      font-style: normal;
      font-weight: 400;
      font-size: 3vw;
      margin-bottom: 2vw;
    }

    .header {
      font-weight: 500;
      font-size: 5vw;
      margin-bottom: 1vw;
    }

    .text {
      font-weight: 300;
      font-size: 2.6vw;
      margin-bottom: 1vw;
    }

    .img {
      margin-bottom: 3vw;
    }

    .box {
      padding: 0 !important;
      margin-bottom: 1vw;
      .featuredBg {
        // height: 10vw;
        padding-top: 3.5vw;
        padding-left: 4vw;

        img {
          // width: 48px;
          width: 8vw;
          // height: 48px;
          height: 8vw;
        }

        .card {
          padding-left: 3vw;
          .text1 {
            color: #101828;
            font-style: normal;
            font-weight: 600;
            // font-size: 20px;
            font-size: 3.5vw;
          }

          .text2 {
            color: #475467;
            font-style: normal;
            font-weight: 400;
            // font-size: 16px;
            font-size: 3vw;
          }

          .text3 {
            color: #6941c6;
            font-style: normal;
            font-weight: 600;
            // font-size: 16px;
            font-size: 3vw;
            width: 80%;
          }
        }
      }
    }

    .labelText {
      font-size: 3.5vw !important;
    }

    .inputGroup {
      button,
      a {
        font-size: 4vw;
      }
    }

    input {
      font-size: 4vw;
    }

    textarea {
      font-size: 4vw;
    }

    .checkBoxGroup {
      margin-top: 3vw !important;
      .checkBoxIcon {
        height: 3vw;
        width: 3vw;
        margin-right: 1vw;
      }

      .checkBox {
        font-size: 3vw;
      }
    }

    .button {
      margin-top: 7vw !important;
      width: fit-content;
      height: fit-content;
      border-radius: 1vw;
      font-size: 3vw;
      padding: 1.5vw 6vw !important;
    }

    .removePadding {
      padding: 0 !important;
    }
  }
}
