.body {
  padding-left: 5% !important;
  padding-right: 5% !important;

  .processTag {
    // width: 75px;
    width: 4vw;
    color: #6941c6;
    border-color: #6941c6 !important;
    border-radius: 15px;
    font-weight: 500;
    // font-size: 14px;
    font-size: 0.75vw;
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.9vw;
    width: 60%;
    color: #101828;
  }

  .bottomBar1 {
    margin-top: 6vw;
    width: 90%;
    // height: 250px;
    background-color: #f9fafb;
    border-radius: 16px;

    // padding-top: 55px;
    padding-top: 2.85vw;
    // padding-bottom: 50px;
    padding-bottom: 2.6vw;

    padding-left: 5%;
    padding-right: 5%;

    .header {
      color: #101828;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      width: 96%;
    }

    .text {
      color: #475467;
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      // line-height: 30px;
    }

    .botton {
      background-color: #7f56d9;
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.8vw;

      border: 1px solid #7f56d9;
      /* Shadow/xs */

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;

      .topText {
        font-style: normal;
        font-weight: 700;
        // font-size: 14px;
        font-size: 0.74vw;
      }

      .bottomText {
        font-style: normal;
        font-weight: 700;
        // font-size: 24px;
        font-size: 1.25vw;
      }
    }
  }

  .bottomBar2 {
    margin-top: 6vw;

    width: 90%;
    // height: 250px;
    background-color: #53389e;
    border-radius: 16px;

    // padding-top: 55px;
    padding-top: 2.85vw;
    // padding-bottom: 50px;
    padding-bottom: 2.6vw;

    padding-left: 5%;
    padding-right: 5%;

    .header {
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
      width: 95%;
    }

    .text {
      color: #e9d7fe;
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      // line-height: 30px;
    }

    .botton {
      background-color: #7f56d9;
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.8vw;

      border: 1px solid #7f56d9;
      /* Shadow/xs */

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;

      .topText {
        font-style: normal;
        font-weight: 700;
        // font-size: 14px;
        font-size: 0.74vw;
      }

      .bottomText {
        font-style: normal;
        font-weight: 700;
        // font-size: 24px;
        font-size: 1.25vw;
      }
    }
  }

  .bottomBar3 {
    margin-top: 6vw;

    width: 90%;
    background-color: #7f56d912;
    border-radius: 16px;
    // padding-top: 55px;
    padding-top: 2.85vw;
    // padding-bottom: 50px;
    padding-bottom: 2.6vw;

    padding-left: 5%;
    padding-right: 5%;

    .header {
      width: 95%;
      color: #101828;

      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 1.9vw;
    }

    .text {
      color: #e9d7fe;
      font-style: normal;
      font-weight: 400;
      // font-size: 20px;
      font-size: 1.05vw;
      line-height: 30px;
    }

    .botton {
      background-color: #7f56d9;
      font-style: normal;
      font-weight: 600;
      // font-size: 16px;
      font-size: 0.8vw;

      border: 1px solid #7f56d9;
      /* Shadow/xs */

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;

      .topText {
        font-style: normal;
        font-weight: 700;
        // font-size: 14px;
        font-size: 0.74vw;
        color: white;
      }

      .bottomText {
        font-style: normal;
        font-weight: 700;
        // font-size: 24px;
        font-size: 1.25vw;
        color: white;
      }
    }
  }
}

// @media (min-width: 1400px) {
//   .bottomBar {
//     height: 270px !important;
//     .header {
//       font-size: 32px !important;
//     }
//     .text {
//       font-size: 20px !important;
//     }
//     .botton {
//       font-size: 16px !important;
//       line-height: 24px !important;
//     }
//   }
// }

@media (max-width: 600px) {
  .body {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    .processTag {
      width: fit-content;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 3vw;
      font-weight: 500;
      font-size: 3vw;
      padding: 0 2vw;
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      // font-size: 36px;
      font-size: 4vw;
      width: 100%;
      color: #101828;
    }

    .bottomBar1 {
      margin-top: 6vw;
      width: 90%;
      // height: 250px;
      background-color: #f9fafb;
      border-radius: 2vw;

      // padding-top: 55px;
      padding-top: 4vw;
      // padding-bottom: 50px;
      padding-bottom: 4vw;

      padding-left: 2.5%;
      padding-right: 2.5%;

      .header {
        color: #101828;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 2.5vw;
        width: 100%;
      }

      .text {
        color: #475467;
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 2vw;
        // line-height: 30px;
      }

      .botton {
        background-color: #7f56d9;
        font-style: normal;
        font-weight: 600;
        // font-size: 16px;
        font-size: 2vw;

        border: 1px solid #7f56d9;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 1vw;

        .topText {
          font-style: normal;
          font-weight: 700;
          // font-size: 14px;
          font-size: 2vw;
        }

        .bottomText {
          font-style: normal;
          font-weight: 700;
          // font-size: 24px;
          font-size: 2vw;
        }
      }
    }

    .bottomBar2 {
      margin-top: 6vw;

      width: 90%;
      // height: 250px;
      background-color: #53389e;
      border-radius: 16px;

      // padding-top: 55px;
      padding-top: 2.85vw;
      // padding-bottom: 50px;
      padding-bottom: 2.6vw;

      padding-left: 2.5%;
      padding-right: 2.5%;

      .header {
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        // font-size: 36px;
        font-size: 1.9vw;
        width: 95%;
      }

      .text {
        color: #e9d7fe;
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.05vw;
        // line-height: 30px;
      }

      .botton {
        background-color: #7f56d9;
        font-style: normal;
        font-weight: 600;
        // font-size: 16px;
        font-size: 0.8vw;

        border: 1px solid #7f56d9;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        .topText {
          font-style: normal;
          font-weight: 700;
          // font-size: 14px;
          font-size: 0.74vw;
        }

        .bottomText {
          font-style: normal;
          font-weight: 700;
          // font-size: 24px;
          font-size: 1.25vw;
        }
      }
    }

    .bottomBar3 {
      margin-top: 6vw;

      width: 90%;
      background-color: #7f56d912;
      border-radius: 2vw;
      padding-top: 4vw;
      padding-bottom: 3vw;

      padding-left: 2.5%;
      padding-right: 2.5%;

      .header {
        width: 100%;
        color: #101828;

        font-style: normal;
        font-weight: 600;
        font-size: 2.5vw;
      }

      .text {
        color: #e9d7fe;
        font-style: normal;
        font-weight: 400;
        font-size: 1.5vw;
        line-height: 2vw;
      }

      .botton {
        background-color: #7f56d9;
        font-style: normal;
        font-weight: 600;
        // font-size: 16px;
        font-size: 1.5vw;

        border: 1px solid #7f56d9;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        width: fit-content;

        .topText {
          font-style: normal;
          font-weight: 700;
          // font-size: 14px;
          font-size: 1.5vw;

          color: white;
        }

        .bottomText {
          font-style: normal;
          font-weight: 700;
          // font-size: 24px;
          font-size: 1.5vw;

          color: white;
        }
      }
    }
  }
}
