.body {
  margin-top: 9vw;
  padding-left: 5%;
  padding-right: 5%;

  margin-bottom: 6vw;

  .frame1 {
    left: 0;
    top: 0;
    width: 28%;
  }

  .frame2 {
    right: 0;
    top: 0;
    width: 24%;
  }

  .header1 {
    font-style: normal;
    font-weight: 400;
    // font-size: 28px;
    font-size: 1.46vw;
    text-transform: uppercase;
    color: #101828;

    span {
      color: #53389e !important;
    }
  }

  .header2 {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 1.88vw;

    color: #101828;
  }

  .text {
    margin-top: 2vw;
    font-style: normal;
    font-weight: 300;
    // font-size: 24px;
    font-size: 1.25vw;

    width: 80%;
    color: #101828;
  }

  .header3 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    font-size: 1.355vw;
    color: #53389e;

    margin-top: 2vw;
    margin-bottom: 3vw;
  }

  .box {
    .card {
      background-color: #f4f6fa;
      border-radius: 0.8vw;

      padding: 2vw 2.5vw;
      img {
        // width: 60px;
        width: 3.15vw;
        // height: 60px;
        height: 3.15vw;
      }

      .title {
        font-style: normal;
        font-weight: 400;
        // font-size: 28px;
        font-size: 1.45vw;

        margin-top: 1vw;
      }

      .text2 {
        font-style: normal;
        font-weight: 400;
        // font-size: 20px;
        font-size: 1.0316vw;

        margin-top: 0.8vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 9vw;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 5%;

    .frame1 {
      left: 0;
      top: 0;
      width: 15%;
      z-index: -1;
    }

    .frame2 {
      right: 0;
      top: 0;
      width: 14%;
      z-index: -1;
    }

    .header1 {
      font-style: normal;
      font-weight: 400;
      font-size: 4vw;

      span {
        color: #53389e !important;
      }
    }

    .header2 {
      font-style: normal;
      font-weight: 500;
      font-size: 5vw;
    }

    .text {
      margin-top: 2vw;
      font-weight: 400;
      font-size: 2.5vw;

      width: 95%;
    }

    .header3 {
      font-style: normal;
      font-weight: 600;
      font-size: 3vw;

      margin-top: 2vw;
      margin-bottom: 3vw;
    }

    .box {
      // padding: 0 !important;
      padding-left: 1% !important;
      padding-right: 1% !important;
      margin-bottom: 3vw;
      .card {
        background-color: #f4f6fa;
        border-radius: 0.8vw;

        padding: 2vw 2.5vw;
        img {
          width: 8vw;
          height: 8vw;
        }

        .title {
          font-style: normal;
          font-weight: 400;
          font-size: 3vw;

          margin-top: 1vw;
        }

        .text2 {
          font-style: normal;
          font-weight: 400;
          // font-size: 20px;
          font-size: 2.5vw;

          margin-top: 0.8vw;
        }
      }
    }
  }
}
