.testmonials {
  padding-left: 12.5% !important;
  padding-right: 12.5% !important;
  margin-top: 6vw;

  .body {
    // height: 560px;
    // height: 30vw;
    border-radius: 5vw;
    padding-top: 4vw;
    padding-bottom: 4vw;

    .testimonialsTag {
      // width: 100px;
      width: fit-content;
      padding: 0 0.5vw;
      border-radius: 0.8vw;
      font-weight: 500;
      // font-size: 14px;
      font-size: 0.7vw;
      line-height: 1.2vw;
      margin-bottom: 2vw;
    }

    .header {
      font-style: normal;
      font-weight: 400;
      // font-size: 48px;
      font-size: 2.4vw;
      // line-height: 60px;
      line-height: 3vw;
      width: 92%;
      margin-bottom: 2vw;
    }

    .profileBody {
      margin-bottom: 2vw;

      img {
        // width: 64px;
        width: 3.3vw;
        // height: 64px;
        height: 3.3vw;
        margin-bottom: 1vw;
      }

      .profileName {
        font-style: normal;
        font-weight: 600;
        // font-size: 18px;
        font-size: 0.95vw;
      }

      .profileDesignation {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.85vw;
      }
    }

    .brandsBox {
      .brands {
        font-style: normal;
        font-weight: 600;
        // font-size: 24px;
        font-size: 1.2vw;

        img {
          height: 2.1vw;
          margin-right: 0.35vw;
        }
        svg {
          height: 2.1vw;
          margin-right: 0.35vw;
        }
      }
    }
  }
}

.testPadding {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
}

@media only screen and (max-width: 600px) {
  .testmonials {
    padding-left: 3% !important;
    padding-right: 3% !important;
    margin-top: 9vw;

    .body {
      border-radius: 8vw;
      padding-top: 4vw;
      padding-bottom: 4vw;

      .testimonialsTag {
        width: fit-content;
        padding: 1.2vw 2vw;
        border-radius: 3vw;
        font-weight: 300;
        font-size: 2.5vw;
        margin-bottom: 3vw;
      }

      .header {
        font-style: normal;
        font-weight: 400;
        font-size: 2.8vw;
        line-height: 4vw;
        width: 95%;
        margin-bottom: 4vw;
      }

      .profileBody {
        margin-bottom: 2vw;

        img {
          // width: 64px;
          width: 8vw;
          // height: 64px;
          height: 8vw;
          margin-bottom: 3vw;
        }

        .profileName {
          font-style: normal;
          font-weight: 400;
          font-size: 2.5vw;
        }

        .profileDesignation {
          font-style: normal;
          font-weight: 400;
          font-size: 2.2vw;
        }
      }

      .brandsBox {
        .brands {
          font-style: normal;
          font-weight: 400;
          font-size: 2.5vw;

          img {
            height: 4vw;
            margin-right: 1vw;
          }
          
        }
      }
    }
  }
}
